<template>
  <v-row justify="center" align-content="center" class="my-12 mx-0">
    <v-card class="mr-6" flat color="transparent">
      <router-link to="/yakiton-teruteru">
        <v-img
          :src="require('@/assets/common/logos/yakiton_logo.png')"
          alt="Brillerが経営するやきとんてるてるのアイコン"
          height="120"
          width="120"
        >
        </v-img>
      </router-link>
    </v-card>

    <v-card flat color="transparent">
      <router-link to="maruteru">
        <v-img
          :src="require('@/assets/common/logos/maruteru_logo.png')"
          alt="Brillerが経営するまるてる食堂のアイコン"
          height="120"
          width="120"
        >
        </v-img>
      </router-link>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: "teruteruLogos"
};
</script>

<style scoped>
</style>
