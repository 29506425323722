<template>
  <v-container id="osakana-menu">
    <div class="menu-relative" align="center">
      <v-img
        :src="require('@/assets/OsakanaTeruteru/sashimi_tempura.jpg')"
        alt="おさかなてるてるのお刺身と天ぷら盛り合わせの写真"
        class="osakana-menu-banner"
      ></v-img>
      <div class="menu-absolute">
        <img
          :src="require('@/assets/OsakanaTeruteru/kodawari_menu.png')"
          alt="おさかなてるてるこだわりメニュー"
          class="kodawari-menu"
          align="center"
        />
      </div>
    </div>
    <v-card-text align="center">
      ※表示価格は全て税抜き価格です。<br />
      ※メニューは一部抜粋しております。<br />
      ※品切れやメニュー変更などの理由からご提供一時停止する場合もございます。<br />
    </v-card-text>
    <v-row class="mb-6" no-gutters>
      <v-col cols="12" sm="6" md="6" lg="6">
        <h3 class="menu-list-title" align="center" justify="center">
          <img
            :src="require('@/assets/OsakanaTeruteru/tuna.png')"
            alt="マグロが飛び跳ねるイラスト"
            height="30"
            class="tuna-illustration"
          />
          料理
          <img
            :src="require('@/assets/OsakanaTeruteru/tuna.png')"
            alt="マグロが飛び跳ねるイラスト"
            height="30"
            class="tuna-illustration"
          />
        </h3>
        <v-treeview
          dense
          open-on-click
          :items="menu.foodList"
          class="menu-lists"
        >
          <template slot="label" slot-scope="{ item }">
            <div class="annotation-style">
              {{ item.message }}
            </div>
            <v-row>
              <v-col>
                {{ item.name }}
              </v-col>
              <v-col align="right">
                {{ item.price }}
              </v-col>
            </v-row>
          </template>
        </v-treeview>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6">
        <h3 class="menu-list-title" align="center" justify="center">
          <img
            :src="require('@/assets/OsakanaTeruteru/tuna.png')"
            alt="マグロが飛び跳ねるイラスト"
            height="30"
            class="tuna-illustration"
          />
          ドリンク
          <img
            :src="require('@/assets/OsakanaTeruteru/tuna.png')"
            alt="マグロが飛び跳ねるイラスト"
            height="30"
            class="tuna-illustration"
          />
        </h3>
        <v-treeview dense open-on-click :items="menu.drinkList" class="menu-lists">
          <template slot="label" slot-scope="{ item }">
            <div class="annotation-style">
              {{ item.message }}
            </div>
            <v-row>
              <v-col>
                {{ item.name }}
              </v-col>
              <v-col align="right">
                {{ item.price }}
              </v-col>
            </v-row>
          </template>
        </v-treeview>
        <div class="annotation-style" align="center">
          ※ワインも取り揃えております。<br />
          詳しくは、店舗へご確認ください。
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-6" no-gutters>
      <v-col cols="12" sm="6" md="6" lg="6">
        <h3 class="menu-list-title" align="center" justify="center">
          <img
            :src="require('@/assets/OsakanaTeruteru/tuna.png')"
            alt="マグロが飛び跳ねるイラスト"
            height="30"
            class="tuna-illustration"
          />
          お持ち帰り
          <img
            :src="require('@/assets/OsakanaTeruteru/tuna.png')"
            alt="マグロが飛び跳ねるイラスト"
            height="30"
            class="tuna-illustration"
          />
        </h3>
        <v-treeview dense open-on-click :items="menu.takeoutList" class="menu-lists">
          <template slot="label" slot-scope="{ item }">
            <div class="annotation-style">
              {{ item.message }}
            </div>
            <v-row>
              <v-col>
                {{ item.name }}
              </v-col>
              <v-col align="right">
                {{ item.price }}
              </v-col>
            </v-row>
          </template>
        </v-treeview>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
        <h3 class="menu-list-title" align="center" justify="center">
          <img
            :src="require('@/assets/OsakanaTeruteru/tuna.png')"
            alt="マグロが飛び跳ねるイラスト"
            height="30"
            class="tuna-illustration"
          />
          ランチ
          <img
            :src="require('@/assets/OsakanaTeruteru/tuna.png')"
            alt="マグロが飛び跳ねるイラスト"
            height="30"
            class="tuna-illustration"
          />
        </h3>
        <v-treeview dense open-on-click :items="menu.lunchList" class="menu-lists">
          <template slot="label" slot-scope="{ item }">
            <div class="annotation-style">
              {{ item.message }}
            </div>
            <v-row>
              <v-col>
                {{ item.name }}
              </v-col>
              <v-col align="right">
                {{ item.price }}
              </v-col>
            </v-row>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import menu from "@/json/menu/osakanaTeruteru.json"

export default {
  name: "Menu",
  data: () => ({
    menu
  })
};
</script>

<style lang="scss" scoped>
.osakana-menu-banner {
  height: 300px;
  width: 90%;
  @include display_smart_phone {
    height: 200px;
  }
}

.kodawari-menu {
  height: 300px;
  @include display_smart_phone {
    height: 200px;
  }
}

.menu-relative {
  position: relative;
}

.menu-absolute {
  position: absolute;
  right: 0px;
  top: 0px;
  @include display_tablet {
    right: 25px;
    top: 0;
  }
  @include display_pc {
    right: 15%;
    top: 0;
  }
}

.tuna-illustration {
  margin: auto 10px;
}

.menu-list-title {
  font-size: 1.5em;
  margin: 40px auto;
  width: 70%;
  height: 40px;
  font-weight: bold;
}

.menu-list-title img {
  vertical-align: middle;
}

.annotation-style {
  font-size: 13px;
}

.menu-lists {
  font-size: 1em;
  margin-left: 35px;
  @include display_pc {
    font-size: 1.2em;
    margin-left: 18%;
  }
}

.menu-png-picture {
  height: 180px;
  widh: 180px;
  @include display_tablet {
    margin-left: 32px;
    height: 150px;
    widh: 150px;
  }
  @include display_pc {
    margin-left: 23px;
    height: 200px;
    widh: 200px;
  }
}
</style>
