<template>
  <v-container id="maruteru-location" class="location-wrapper">
    <h2 class="maruteru-location">まるてる食堂<br class="sp-br" />店舗情報</h2>
    <div class="black--text mb-4" align="center">
      -LOCATION-
    </div>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" lg="6" class="store-picture">
        <v-card flat color="transparent">
          <v-img
            :src="require('@/assets/Maruteru/outside_maruteru.jpg')"
            alt="まるてる食堂の外装写真"
            height="400"
            class="location-image"
          ></v-img>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          class="pa-2 location-detail-wrapper vertical-text"
          elevation="0"
          color="transparent"
        >
          <h3 class="sub-title sub-title-center">
            まるてる食堂江古田店
          </h3>
          <div class="background-circle">
            <h4 class="sub-title location-font-family">
              店舗住所
            </h4>
            <div class="location-font-family">
              東京都練馬区栄町6-31
            </div>

            <h4 class="sub-title location-font-family">
              アクセス
            </h4>
            <div class="location-font-family">
              西武池袋線江古田駅より徒歩3分 <br />
              <v-btn
                href="https://goo.gl/maps/CUkB4kaEnZpaoKQ57"
                target="_blank"
                rel="noopener"
                elevation="0"
                color="transparent"
                class="black--text maruteru-location-map location-font-family"
              >
                <v-icon>mdi-chevron-double-right</v-icon>
                Googleマップで開く</v-btn
              >
            </div>
            <h4 class="sub-title location-font-family">
              営業時間
            </h4>
            <div class="location-font-family">
              平日・土日：11:00 - 23:30 <br />
              Lo：フード22:30 / ドリンク 23:00
            </div>
            <v-row no-gutters>
              <v-col cols="5" sm="6">
                <h4 class="sub-title location-font-family">
                  電話番号
                </h4>
                <div>
                  <a
                    href="tel:0363862418"
                    class="phone-number black--text location-font-family"
                    >03-6386-2418</a
                  >
                </div>
              </v-col>
              <v-col class="ml-2">
                <h4 class="sub-title background-image">
                  店舗SNS
                </h4>
                <v-btn
                  v-for="item in maruteruEkodaSnsItems"
                  :key="item.id"
                  :href="item.link"
                  class="mr-4"
                  :color="item.color"
                  icon
                >
                  <v-icon size="32px">
                    {{ item.icon }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <h4 class="sub-title location-font-family">
              支払い方法
            </h4>
            <div class="location-font-family">
              現金・PayPay
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Location",
  data: () => {
    return {
      maruteruEkodaSnsItems: [
        {
          id: 0,
          icon: "mdi-instagram",
          link: "#",
          color: "instagram"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          link: "#",
          color: "facebook"
        },
        {
          id: 2,
          icon: "mdi-twitter",
          link: "https://twitter.com/maruteru_ekoda",
          color: "twitter"
        }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.maruteru-location {
  font-family: yamamotoan-stdn, yamamotoan-classic-stdn, $main-font-family;
  font-style: normal;
  letter-spacing: 0.8em;
  font-size: 1.8em;
  text-align: center;
  padding-top: 30px;
  @include display_smart_phone {
    font-size: 1.5em;
  }
}

.sp-br {
  @include display_tablet {
    display: none;
  }
}

.location-wrapper {
  background-color: $maruteru_main_color;
}

.background-circle {
  height: 350px;
  width: 350px;
  border-radius: 50% !important;
  background-color: $maruteru_sub_color;
  margin: 10px auto;
  align-items: center;
  justify-content: center;
  @include display_smart_phone {
    height: 300px;
    width: 300px;
  }
}

.location-image {
  margin-left: 0;
  @include display_pc {
    margin-left: 20px;
  }
}

.sub-title {
  margin-top: 8px;
}

.phone-number {
  @include display_pc {
    pointer-events: none;
  }
}

.sub-title-center {
  @include display_tablet {
    margin-left: 25%;
  }
}

h3 {
  color: black;
  padding: 0.5em;
  display: inline-block;
  line-height: 1.3;
  background: $maruteru_accent_color;
  vertical-align: middle;
  border-radius: 25px 0px 0px 25px;
  border: 3px solid white;
}

h3:before {
  content: "●";
  color: white;
  margin-right: 8px;
}

.location-detail-wrapper {
  margin: 20px auto;
  @include display_smart_phone {
    margin: 10px auto 50px;
  }
}

.maruteru-location-map {
  border: 1px solid black !important;
  margin-top: 8px;
}

.store-picture {
  display: flex;
  align-items: center;
  justify-content: center;
}

.location-font-family {
  font-family: $messages-font-family;
}
</style>
