<template>
  <v-container id="osakana-banner" class="justify-center">
    <v-card>
      <v-img
        :src="require('@/assets/OsakanaTeruteru/osakana_banner.jpg')"
        class="osakana-banner-image"
        alt="Brillerの経営するおさかなてるてるの店舗写真"
      >
        <v-row class="fill-height ma-0" align="center" justify="center">
          <div class="pt-8 pb-8 pl-12 pr-12 banner">
            <img
              :src="require('@/assets/common/logos/osakana_logo.png')"
              class="osakana-logo"
              alt="おさかなてるてるの店舗ロゴ"
            />
          </div>
        </v-row>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Banner"
};
</script>

<style lang="scss" scoped>
.osakana-banner-image {
  height: $banner-height;
  width: $banner-width;
}

.logo-center {
  margin: 10px auto;
}

.osakana-logo {
  height: 150px;
  width: 150px;
  @include display_smart_phone {
    height: 130px;
    width: 130px;
  }
}
</style>
