<template>
  <v-container id="job-description-sns">
    <v-card flat tile class="text-center" width="100%">
      <v-card-text class="pb-0">
        <ShareNetwork
          network="twitter"
          url="https://briller-jp.com/job-description"
          title="飲食店経営会社Briller(ブルイエ)の求人情報をシェア！ブルイエ各店舗では一緒に働ける仲間を募集中！"
          description="飲食店経営会社Briller(ブルイエ)の求人情報！やきとんてるてる、まるてる食堂などてるてる各店舗では従業員を募集しています！"
          hashtags="合同会社Briller,ブルイエ,従業員募集,高田馬場,東中野,ひばりヶ丘,中板橋,江古田,大衆居酒屋,定食屋,飲食店"
          class="mr-4"
        >
          <v-btn color="twitter" class="white--text">
            {{ snsItems[0].message }}
            <v-icon class="sns-icons white--text">
              {{ snsItems[0].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
        <ShareNetwork
          network="facebook"
          url="https://briller-jp.com/job-description"
          title="飲食店経営会社Briller(ブルイエ)の求人情報をシェア！ブルイエ各店舗では一緒に働ける仲間を募集中！"
          description="飲食店経営会社Briller(ブルイエ)の求人情報！やきとんてるてる、まるてる食堂などてるてる各店舗では従業員を募集しています！"
          quote="飲食店経営会社Briller(ブルイエ)の求人情報！やきとんてるてる、まるてる食堂などてるてる各店舗では従業員を募集しています！"
          hashtags="飲食店経営会社Brillerブルイエの求人情報"
          class="mr-4"
        >
          <v-btn color="facebook" class="white--text">
            {{ snsItems[1].message }}
            <v-icon class="sns-icons white--text">
              {{ snsItems[1].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "SnsShare",
  data: () => {
    return {
      snsItems: [
        {
          id: 0,
          icon: "mdi-twitter",
          message: "ツイート"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          message: "シェアする"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  font-family: $footer-font-family;
}

a {
  text-decoration: none;
}
</style>
