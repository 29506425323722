<template>
  <v-container id="maruteru-main-message">
    <v-card class="maruteru-message-title" elevation="0" color="transparent">
      <h1>
        どこか懐かしい気持ちになり<br />
        それでいてここでしか味わえないまるてる食堂の定食
      </h1>
    </v-card>
    <v-card class="maruteru-message" elevation="0" color="transparent">
      日本の伝統的な食事スタイルである定食は一汁三菜を基本とした、世界でも注目される健康的でバランスの取れた食事です。
      毎日を忙しく過ごす中、栄養バランスは二の次になってしまいがちで、偏った食生活を送っている方が多いのではないかと思います。
      まるてる食堂では、いつでも栄養満点の食事を現代社会に暮らす全ての方に取って頂くため、ゆっくり店内でお召し上がり頂くのにも、
      お持ち帰り頂きオフィスやご自宅でお召し上がり頂くのにも美味しい、数多くの定食やお惣菜をご用意しております。
      定食屋では少ない串物や揚げ物、お店で直接捌いた新鮮なお刺身などメニューも豊富！
      食堂としても居酒屋としてもその日の気分でメニューを変えれる新しい食堂としてご利用ください！
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "MainMessage"
};
</script>

<style lang="scss" scoped>
.maruteru-message-title {
  margin: 20px auto;
  text-align: center;
  font-size: 1em;
}

.maruteru-message {
  margin: 30px 50px;
  font-size: 1em;
  @include display_smart_phone {
    margin: 20px 8px;
  }
  @include display_pc {
    font-size: 1.3em;
  }
}

.sp-br {
  @include display_tablet {
    display: none;
  }
}
</style>
