<template>
  <v-container id="maruteru-menu">
    <v-card flat>
      <v-img
        :src="require('@/assets/Maruteru/maruteru_banner.jpg')"
        alt="まるてる食堂のモツ煮込み定食の写真"
        height="230"
        width="100%"
      >
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
          no-gutters
        >
          <div class="pt-8 pb-8 pl-12 pr-12">
            <h2
              class="font-weight-light black--text font-weight-bold main-title"
            >
              お品書き
            </h2>
            <div
              class="subtitle-1 black--text font-weight-bold text-decoration-underline"
              align="center"
            >
              MENU
            </div>
          </div>
        </v-row>
      </v-img>
    </v-card>
    <v-card-text align="center">
      ※表示価格は全て税抜き価格です。<br />
      ※メニューは一部抜粋しております。<br />
      ※品切れやメニュー変更などの理由からご提供一時停止する場合もございます。<br />
    </v-card-text>
    <v-row class="mb-6" no-gutters>
      <v-col class="menu-list pa-0" cols="12" sm="6" md="6" lg="6">
        <h3 class="menu-list-title" align="center">
          料理
        </h3>
        <v-card-text align="center">
          定食メニュー味噌汁・おしんこ付き + ごはんおかわり自由
        </v-card-text>
        <v-treeview
          dense
          open-on-click
          :items="menu.foodList"
          class="lists"
        >
          <template slot="label" slot-scope="{ item }">
            <div class="annotation-style">
              {{ item.message }}
            </div>
            <v-row no-gutters>
              <v-col>
                {{ item.name }}
              </v-col>
              <v-col align="right">
                {{ item.price }}
              </v-col>
            </v-row>
          </template>
        </v-treeview>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6">
        <h3 class="menu-list-title" align="center">
          ドリンク
        </h3>
        <v-treeview dense open-on-click :items="menu.drinkList" class="lists">
          <template slot="label" slot-scope="{ item }">
            <div class="annotation-style">
              {{ item.message }}
            </div>
            <v-row no-gutters>
              <v-col>
                {{ item.name }}
              </v-col>
              <v-col align="right">
                {{ item.price }}
              </v-col>
            </v-row>
          </template>
        </v-treeview>
        <div class="annotation-style" align="center">
          ※ワインも取り揃えております。<br />
          詳しくは、店舗へご確認ください。
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import menu from '@/json/menu/maruteru.json';

export default {
  name: "Menu",
  data: () => ({
    menu
  }),
};
</script>

<style lang="scss" scoped>
.main-title {
  font-size: 2em;
  @include display_pc {
    font-size: 3em;
  }
}

.menu-list-title {
  font-size: 1.5em;
  margin: 40px auto;
  width: 50%;
  text-shadow: 0 0 5px white;
  padding: 0.3em 0.5em;
  background: -webkit-repeating-linear-gradient(
    -45deg,
    $maruteru_main_color,
    $maruteru_main_color 3px,
    $maruteru_sub_color 3px,
    $maruteru_sub_color 7px
  );
  background: repeating-linear-gradient(
    -45deg,
    $maruteru_main_color,
    $maruteru_main_color 3px,
    $maruteru_sub_color 3px,
    $maruteru_sub_color 7px
  );
  border-radius: 5px;
}

.background-image {
  margin: 20px auto;
  align-items: baseline;
  justify-content: center;
}

.lists {
  font-size: 1em;
  margin-left: 35px;
  @include display_pc {
    font-size: 1.2em;
    margin-left: 18%;
  }
}
</style>
