<template>
  <v-container id="kodomo-syokudo">
    <NavBar></NavBar>
    <LogoBanner></LogoBanner>
    <Banner></Banner>
    <MainMessage></MainMessage>
    <ActivityDetail></ActivityDetail>
    <Footer>
      <template v-slot:sns-share>
        <SnsShare></SnsShare>
      </template>
    </Footer>
  </v-container>
</template>

<script>
import LogoBanner from "@/components/Common/BrillerLogoBanner";
import Banner from "@/components/KodomoSyokudo/Banner";
import MainMessage from "@/components/KodomoSyokudo/MainMessage";
import ActivityDetail from "@/components/KodomoSyokudo/ActivityDetail";
import Footer from "@/components/Common/Footer";
import NavBar from "@/components/Common/NavBar";
import SnsShare from "@/components/KodomoSyokudo/SnsShare";

export default {
  name: "KodomoSyokudo",
  components: {
    SnsShare,
    NavBar,
    Footer,
    ActivityDetail,
    MainMessage,
    Banner,
    LogoBanner
  }
};
</script>

<style scoped>
* {
  padding: 0;
}
</style>
