<template>
  <v-container id="briller-logo-banner">
    <router-link to="/">
      <div class="image-center">
        <img
          :src="require('@/assets/briller/briller_logo.png')"
          alt="Briller-ブルイエ-のアイコン"
          height="98"
          width="300"
          class="briller-banner-logo"
        />
      </div>
    </router-link>
  </v-container>
</template>

<script>
export default {
  name: "BrillerLogoBanner"
};
</script>

<style lang="scss" scoped>
.image-center {
  display: flex;
  align-content: center;
  justify-content: center;
}

.briller-banner-logo {
  @include display_smart_phone {
    height: 80px;
    width: 240px;
  }
}
</style>
