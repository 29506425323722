<template>
  <v-container id="kodomo-syokudo-main-message">
    <v-row no-gutters>
      <v-col class="pa-0 mt-8">
        <v-card-text flat align="center" class="pa-0">
          <h2 class="kodomo-syokudo-title">
            こども食堂
          </h2>
        </v-card-text>
      </v-col>
      <v-card-text class="kodomo-syokudo-message" align="center">
        合同会社Brillerでは企業活動としてこども食堂を定期開催しています。
      </v-card-text>
    </v-row>
    <div class="title-center">
      <v-row
        align-content="center"
        justify="center"
        no-gutters
        class="d-flex my-10 py-5 pl-1 about-kodomo-syokudo-wrapper"
      >
        <v-col cols="12" sm="4" md="4" lg="4">
          <h2 class="about-kodomo-syokudo-title">
            こども食堂とは
          </h2>
        </v-col>
        <v-col cols="12" sm="8" md="8" lg="8">
          <v-card class="about-kodomo-syokudo" elevation="0">
            現在、日本では17歳未満の子供の
            約13%と7人に1人が貧困層だと言われており、
            豊かと言われる日本で多くの子供達が
            美味しいご飯をお腹いっぱい食べることができない状況となっています。
            貧困層に限らず、共働きが一般的となってきた近年
            ひとりでご飯を食べる子供たちも少なくありません。
            そんな状況を少しでも改善できないかと動き出した活動がこども食堂です。<br />
            そこにはいくつかの問題があり、その一つが活動場所の確保です。
            お食事処を運営している私たちにできることが、たくさんあると考え
            こども食堂の普及を願い定期的に食堂の開催をしています。
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "MainMessage"
};
</script>

<style lang="scss" scoped>
.kodomo-syokudo-title {
  position: relative;
  font-weight: bold;
  width: 50%;
  font-size: 1.5rem;
  margin: 13px auto;
  padding: 25px 8px;
  @include display_pc {
    font-size: 2rem;
    margin: 25px auto;
  }
}

.kodomo-syokudo-title:before,
.kodomo-syokudo-title:after {
  content: "";
  width: 20px;
  height: 30px;
  position: absolute;
  display: inline-block;
}

.kodomo-syokudo-title:before {
  border-left: solid 1px $briller_sub_color;
  border-top: solid 1px $briller_sub_color;
  top: 0;
  left: 0;
}

.kodomo-syokudo-title:after {
  border-right: solid 1px $briller_sub_color;
  border-bottom: solid 1px $briller_sub_color;
  bottom: 0;
  right: 0;
}

.kodomo-syokudo-message {
  font-size: 1.3em;
}

.title-center {
  display: flex;
  justify-content: center;
}

.about-kodomo-syokudo-wrapper {
  font-size: 1em;
  border-bottom: solid 2px black;
  border-top: solid 2px black;
  width: 40%;
  @include display_pc {
    font-size: 1.3em;
  }
}

.about-kodomo-syokudo-title {
  margin: 10px 15px;
}

.about-kodomo-syokudo {
  margin: auto 10px;
  @include display_smart_phone {
    margin: auto 15px;
    font-size: 1.2em;
  }
}
</style>
