<template>
  <v-container id="briller-carousel">
    <div id="carousel-slider">
      <v-carousel
        align="center"
        justify="center"
        height="400"
        hide-delimiter-background
      >
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          :alt="item.alt"
        ></v-carousel-item>
      </v-carousel>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      items: [
        {
          src: require("@/assets/briller/CarouselPictures/gutsugutsu.jpg"),
          alt: "やきとんてるてる人気メニュータコのグツグツ煮の写真"
        },
        {
          src: require("@/assets/briller/CarouselPictures/sashimi_tempura.jpg"),
          alt: "鮮魚の刺身と天ぷらの写真"
        },
        {
          src: require("@/assets/briller/CarouselPictures/motsu.jpg"),
          alt: "まるてる食堂一押しの大きな釜でじっくり煮込んだ持つ煮込みの写真"
        },
        {
          src: require("@/assets/briller/CarouselPictures/kushi_beer.jpg"),
          alt: "相性抜群のやきとんてるてるの串もりとビールの写真"
        },
        {
          src: require("@/assets/briller/CarouselPictures/karaage.jpg"),
          alt: "まるてる食堂の大人気の一品、デカ唐揚げの写真"
        },
      ]
    };
  }
};
</script>
