<template>
  <v-container id="maruteru-carousel">
    <div
      id="maruteru-carousel-slider"
      align="center"
      class="maruteru-carousel-wrapper"
    >
      <v-carousel hide-delimiter-background height="300" class="carousel_inner">
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          :alt="item.alt"
          class="carousel-items"
        ></v-carousel-item>
      </v-carousel>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      items: [
        {
          src: require("@/assets/Maruteru/karaage_teisyoku.jpg"),
          alt: "まるてる食堂で大人気のデカ唐揚げ定食の写真"
        },
        {
          src: require("@/assets/Maruteru/motsu_teisyoku.jpg"),
          alt: "まるてる食堂一押しのじっくり煮込んだモツ煮込み定食の写真"
        },
        {
          src: require("@/assets/Maruteru/motsu_pot.jpg"),
          alt: "大きな釜でじっくり煮込んだモツ煮込みの写真"
        },
        {
          src: require("@/assets/Maruteru/maruteru_syokudo.jpg"),
          alt: "まるてる食堂の店内写真"
        },
        {
          src: require("@/assets/Maruteru/outside_maruteru.jpg"),
          alt: "まるてる食堂の外装写真"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
#maruteru-carousel-slider {
  margin: 15px auto;
  width: 60%;
  @include display_smart_phone {
    width: 100%;
  }
}

.maruteru-carousel-wrapper {
  border-top: 5px double black;
  border-bottom: 5px double black;
  padding: 20px;
}
</style>
