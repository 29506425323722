<template>
  <v-container id="associated-location">
    <div class="title-center">
      <v-card
        class="d-flex justify-center my-10 py-12 rounded-0 main-title"
        elevation="0"
        width="80%"
      >
        <h2>
          ブルイエ系列事業・店舗紹介
        </h2>
      </v-card>
    </div>
    <v-card flat class="location-name rounded-0" align="center">
      <h3>
        <router-link to="/yakiton-teruteru"
          >やきとん<br class="sp-br" />てるてる
        </router-link>
      </h3>
    </v-card>

    <div class="background-color-left">
      <div class="background-color-absolute-left">
        <v-row class="introduce-container introduce-reverse" no-gutters>
          <v-col>
            <v-card-text class="sub-title">
              <h4>
                ここににしかない居心地<br />
                近いからこそできる<br class="sp-br" />おもてなし
              </h4>
            </v-card-text>
            <v-card flat color="transparent">
              <v-card-text class="introduce-messages black--text" elevation="0">
                お客様が居心地よく過ごしていただけるよう
                やきとんてるてるでは、スタッフの個性や人柄を生かした活気のある店づくりに励んでいます。
                大衆居酒屋の魅力である、気軽さ、活気、温かみの全てを小さなお店に詰め込み
                「今日も楽しかった」と一日を締め括って頂けるよう全力を尽くします。
                大人気のほろりと溶けるもつ煮込みや、カリッと程よく香ばしい串メニューも多数取り揃えて、皆様のお越しをお待ちしております。
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <router-link to="/yakiton-teruteru">
              <v-card flat class="mt-13">
                <v-img
                  :src="require('@/assets/common/logos/yakiton_logo.png')"
                  alt="Brillerが経営するやきとんてるてるのアイコン"
                  height="150"
                  width="150"
                  class="icon-on-right position-absolute"
                >
                </v-img>
              </v-card>
              <v-card>
                <v-img
                  :src="require('@/assets/briller/motsu_nikomi.jpg')"
                  alt="Brillerが経営するやきとんてるてるのモツ煮込み"
                  height="350"
                  width="max"
                  class="position-relative"
                >
                </v-img>
              </v-card>
            </router-link>
            <v-card
              class="pa-2 mr-2"
              align="center"
              justify="center"
              elevation="0"
              color="transparent"
            >
              <v-btn
                text
                elevation="0"
                to="/yakiton-teruteru"
                class="store-link rounded-0"
              >
                店舗ページへ移動
                <v-icon dark right>mdi-chevron-double-right</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-card flat class="location-name rounded-0" align="center">
      <h3>
        <router-link to="maruteru">
          まるてる<br class="sp-br" />食堂
        </router-link>
      </h3>
    </v-card>
    <v-col class="pa-2 mr-2" align="center" justify="center" elevation="0">
    </v-col>
    <div class="background-color-left">
      <div class="background-color-absolute-left">
        <v-row class="introduce-container" no-gutters>
          <v-col>
            <v-card-text class="sub-title">
              <h4>
                バランスを考えた<br />
                栄養満点のお料理を一膳に
              </h4>
            </v-card-text>
            <v-card flat color="transparent">
              <v-card-text class="introduce-messages black--text" elevation="0">
                古くから日本人に親しみの深い定食。朝食、昼食、もちろん夜や夜食としても
                馴染みがあり、栄養や見た目にもバランスの整った食事スタイルです。
                その他にもガッツリとお腹を満たしてくれる丼ものや揚げ物、串類なども多数ご用意！
                清潔感と温かみのある店内で食べる日本食でどこか懐かしい気持ちになって
                また帰ってきたいなと思って頂けるよう、まるてる食堂では日々工夫を凝らし
                お客様の御来店をお待ちしております。
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <router-link to="/maruteru">
              <v-card flat class="mt-13">
                <v-img
                  :src="require('@/assets/common/logos/maruteru_logo.png')"
                  alt="Brillerが経営するまるてる食堂のアイコン"
                  height="150"
                  width="150"
                  class="icon-on-left position-absolute"
                >
                </v-img>
              </v-card>
              <v-card>
                <v-img
                  :src="require('@/assets/briller/karaage_teisyoku.jpg')"
                  alt="Brillerが経営するまるてる食堂のからあげ定食"
                  height="300"
                  width="max"
                  class="position-relative"
                >
                </v-img>
              </v-card>
            </router-link>
            <v-card
              class="pa-2 mr-2"
              align="center"
              justify="center"
              elevation="0"
              color="transparent"
            >
              <v-btn
                text
                to="/maruteru"
                elevation="0"
                class="store-link rounded-0"
              >
                店舗ページへ移動
                <v-icon dark right>mdi-chevron-double-right</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>


    <v-card flat class="location-name rounded-0" align="center">
      <h3>
        <router-link to="maruteru">
          ネイス体操教室<br class="sp-br" />(沖縄浦添パルコシティ校)
        </router-link>
      </h3>
    </v-card>
    <v-col class="pa-2 mr-2" align="center" justify="center" elevation="0">
    </v-col>
    <div class="background-color-left">
      <div class="background-color-absolute-left">
        <v-row class="introduce-container" no-gutters>
          <v-col>
            <v-card-text class="sub-title">
              <h4>
                夢中体験を、次々と。
              </h4>
            </v-card-text>
            <v-card flat color="transparent">
              <v-card-text class="introduce-messages black--text" elevation="0">
                ネイス体操教室は、歩きはじめのお子様から体操を通して、「“すこやかなカラダ”と“しなやかなココロ”」を育てる体操教室です。<br />
                ネイス体操教室では、お子様の年齢やニーズに合わせたレッスンをご用意しております。
                またお子様の素敵な思い出づくりや学びの場になるよう、体験イベント施策も推進しています。
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <a href="https://neis-gym.com/class/class_1057/" target="_blank">
              <v-card flat class="mt-13">
              </v-card>
              <v-card>
                <v-img
                    :src="require('@/assets/briller/neis-urazoe.jpg')"
                    alt="Brillerが経営するまるてる食堂のからあげ定食"
                    height="300"
                    width="max"
                    class="position-relative"
                >
                </v-img>
              </v-card>
            </a>
            <v-card
                class="pa-2 mr-2"
                align="center"
                justify="center"
                elevation="0"
                color="transparent"
            >
              <v-btn
                  text
                  href="https://neis-gym.com/class/class_1057/"
                  target="_blank"
                  elevation="0"
                  class="store-link rounded-0"
              >
                店舗ページへ移動
                <v-icon dark right>mdi-chevron-double-right</v-icon>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "AssociatedLocation"
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

a:link,
a:visited,
a:hover,
a:active {
  color: black;
}

.title-center {
  display: flex;
  justify-content: center;
}

.main-title {
  border-bottom: solid 2px black;
  border-top: solid 2px black;
  background-color: transparent;
}

.sub-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  margin: 18px auto 0;
  background-color: rgb(211 203 198);
  @include display_pc {
    margin: 42px auto 0;
  }
}

h4 {
  position: relative;
  line-height: 1.4;
  padding: 0.25em 1em;
  display: inline-block;
}

h4:before,
h4:after {
  content: "";
  width: 20px;
  height: 30px;
  position: absolute;
  display: inline-block;
}

h4:before {
  border-left: solid 1px $briller_sub_color;
  border-top: solid 1px $briller_sub_color;
  top: 0;
  left: 0;
}

h4:after {
  border-right: solid 1px $briller_sub_color;
  border-bottom: solid 1px $briller_sub_color;
  bottom: 0;
  right: 0;
}

.location-name {
  font-weight: bold;
  margin: 0 auto;
  padding-bottom: 12px;
  font-size: 1.3em;
  width: 50%;
  background-color: transparent;
  border-bottom: rgb(211 203 198) solid 2px;
  @include display_pc {
    margin-bottom: 18px;
  }
}

.sp-br {
  @include display_pc {
    display: none;
  }
}

.introduce-messages {
  font-size: 1em;
  line-height: 1.8em;
  @include display_pc {
    padding: 0 48px 0;
    margin: 18px 0 25px;
    font-size: 1.2em;
    line-height: 2em;
  }
  @include display_smart_phone {
    font-family: $messages-font-family;
  }
}

.background-color-left {
  @include display_pc {
    margin-bottom: 25vh;
    background-color: rgb(211 203 198);
    width: 50%;
    height: 650px;
    position: relative;
  }
  @include display_pc_medium {
    height: 550px;
  }
}

.background-color-absolute-left {
  @include display_pc {
    position: absolute;
    right: -90%;
  }
}

.background-color-right {
  @include display_pc {
    margin: 0 0 25vh auto;
    background-color: rgb(211 203 198);
    width: 50%;
    height: 650px;
    position: relative;
  }
  @include display_pc_medium {
    height: 550px;
  }
}

.background-color-absolute-right {
  @include display_pc {
    position: absolute;
    left: -90%;
  }
}

.introduce-container {
  margin-bottom: 56px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  z-index: 100;
}

.icon-on-right {
  left: 0px;
  top: -20px;
}

.icon-on-left {
  right: 0px;
  top: -25px;
}

.maruteru-icon {
  right: 0;
  top: -25px;
}

.introduce-reverse {
  flex-direction: column-reverse;
  @include display_pc {
    flex-direction: row;
  }
}

.store-link {
  font-size: 1.3em;
  margin: 20px auto 10px;
  border-bottom: 1px solid black;
}
</style>
