<template>
  <div>
    <vue-loading
      type="spiningDubbles"
      color="white"
      :size="{ width: '40px', height: '40px' }"
    >
    </vue-loading>
  </div>
</template>

<script>
import { VueLoading } from "vue-loading-template";

export default {
  name: "Loading",
  components: {
    VueLoading
  }
};
</script>

<style></style>
