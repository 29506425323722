<template>
  <v-container id="main-message">
    <v-col class="pa-0">
      <v-card-text flat align="center">
        <h1 class="employee-recruitment-title">
          ブルイエ<br class="sp-br" />の仲間募集
        </h1>
      </v-card-text>
    </v-col>
    <v-col>
      <v-card class="pa-2 message-title" elevation="0" color="transparent">
        <h2 class="employee-recruitment-sub-title">
          お客様だけではなく<br
            class="sp-br"
          />一緒に働く仲間や関わる全ての人も<br />幸せにするために。
        </h2>
      </v-card>
      <v-col class="pa-0">
        <v-card class="messages" elevation="0" color="transparent">
          一緒に働く人を幸せにできず、お客様を幸せにできないと私たちは考えています。<br />
          あらゆる面での質を追求し、私たち自身が仕事に夢や誇りを持ち、心から幸せであること。<br />
          そして、それを共有することが関わる全ての人の幸せに繋がると知っているから。
          私たちはそんな想いを共有できる仲間を募集しています。
        </v-card>
      </v-col>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "Messages"
};
</script>

<style lang="scss" scoped>
.message-title {
  font-size: 1em;
  display: flex;
  justify-content: center;
  text-align: center;
  @include display_pc {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.sp-br {
  @include display_pc {
    display: none;
  }
}

.employee-recruitment-title {
  position: relative;
  font-weight: bold;
  width: 50%;
  font-size: 1.8rem;
  margin: 13px auto;
  padding: 25px 8px;
  line-height: 2.5rem;
  @include display_pc {
    font-size: 2rem;
    margin: 25px auto;
  }
}

.employee-recruitment-title:before,
.employee-recruitment-title:after {
  content: "";
  width: 20px;
  height: 30px;
  position: absolute;
  display: inline-block;
}

.employee-recruitment-title:before {
  border-left: solid 1px $briller_sub_color;
  border-top: solid 1px $briller_sub_color;
  top: 0;
  left: 0;
}

.employee-recruitment-title:after {
  border-right: solid 1px $briller_sub_color;
  border-bottom: solid 1px $briller_sub_color;
  bottom: 0;
  right: 0;
}

.employee-recruitment-sub-title {
  font-size: 1.5rem;
}

.messages {
  font-family: $messages-font-family;
  font-size: 1em;
  letter-spacing: 0.3em;
  line-height: 1.5em;
  padding: 0;
  margin: 18px 0;
  @include display_tablet {
    margin: 20px 35px;
  }
  @include display_pc {
    font-size: 1.2em;
    letter-spacing: 0.3em;
    line-height: 2em;
    padding: 8px;
    margin: 20px 30px;
  }
}
</style>
