<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
  created() {
    const meta = this.$router.history.current.meta;
    this.setTitle(meta);
    this.setIcon(meta);
    this.setOgp(meta);
  },
  watch: {
    $route(to) {
      const meta = to.meta;
      this.setTitle(meta);
      this.setIcon(meta);
      this.setOgp(meta);
    }
  },
  methods: {
    setTitle: meta => {
      document.title = `${meta.title}`;
    },
    setIcon: meta => {
      document.querySelector("[rel='icon']").setAttribute("href", meta.icon);
    },
    setOgp: meta => {
      const metaTags = ["og:title", "og:type", "og:url", "og:image"];
      for (const tag of metaTags) {
        const property = document.querySelector(`meta[property="${tag}"]`);
        if (property && meta[`${tag}`]) {
          property.setAttribute("content", meta[`${tag}`]);
        }
      }
    }
  }
};
</script>
<style>
* {
  font-family: fot-tsukubrdgothic-std, Tsukushi B Round Gothic,
    Tsukushi A Round Gothic, Hiragino Maru Gothic Pro, YuGothic,
    Hiragino Kaku Gothic ProN, Helvetica, sans-serif;
}
</style>
