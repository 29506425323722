<template>
  <v-container id="osakana-location">
    <v-card>
      <v-img
        :src="require('@/assets/OsakanaTeruteru/osakana_banner.jpg')"
        alt="おさかなてるてる店舗外装写真"
        height="230"
        width="100%"
      >
        <v-row class="fill-height ma-0" align="center" justify="center">
          <div class="py-8 px-12">
            <h2
              class="font-weight-light banner-font-style font-weight-bold main-title"
            >
              店舗情報
            </h2>
            <div
              class="subtitle-1 banner-font-style font-weight-bold text-decoration-underline"
              align="center"
            >
              LOCATION
            </div>
          </div>
        </v-row>
      </v-img>
    </v-card>

    <v-row class="location direction-reverse" no-gutters>
      <v-col cols="12" sm="6" md="6" lg="6" class="store-picture">
        <v-card color="transparent">
          <v-img
            :src="require('@/assets/OsakanaTeruteru/outside_osakana.jpg')"
            class="location-image"
            alt="おさかなてるてるの外装の写真"
          ></v-img>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          class="pa-2 location-detail-wrapper vertical-text"
          elevation="0"
          color="transparent"
        >
          <h3 class="sub-title">
            おさかなてるてる中板橋
          </h3>
          <div class="location-detail">
            <h4 class="sub-title location-detail-color">
              店舗住所
            </h4>
            <div class="location-detail-color">
              東京都板橋区中板橋18-9
            </div>

            <h4 class="sub-title location-detail-color">
              アクセス
            </h4>
            <div class="location-detail-color">
              東武東上線中板橋駅より徒歩5分 <br />
              <v-btn
                href="https://g.page/osakana_teruteru?share"
                target="_blank"
                rel="noopener"
                elevation="0"
                color="transparent"
                class="location-detail-color osakana-location-map"
              >
                <v-icon>mdi-chevron-double-right</v-icon>
                Googleマップで開く</v-btn
              >
            </div>
            <h4 class="sub-title location-detail-color">
              営業時間
            </h4>
            <div class="location-detail-color">
              平日・土日：11:30 - 23:30 <br />
              Lo：フード22:30 / ドリンク 23:00
            </div>
            <h4 class="sub-title location-detail-color">
              電話番号
            </h4>
            <div>
              <a
                href="tel:0369057790"
                class="phone-number location-detail-color"
                >03-6905-7790</a
              >
            </div>
            <h4 class="sub-title location-detail-color">
              支払い方法
            </h4>
            <div class="location-detail-color">
              現金・PayPay・電子マネー（交通系含む）
            </div>
            <v-row no-gutters>
              <v-col cols="5" sm="6">
                <h4 class="sub-title location-detail-color">
                  テイクアウト注文アプリ
                </h4>
                <v-btn
                  v-for="item in takeoutApps"
                  :key="item.id"
                  :href="item.link"
                  class="mr-4 mt-2"
                  color="transparent"
                  target="_blank"
                  elevation="0"
                  rel="noopener"
                  icon
                >
                  <v-img :src="item.src" height="40px" width="40px"> </v-img>
                </v-btn>
              </v-col>
              <v-col class="ml-2">
                <h4 class="sub-title location-detail-color">
                  店舗SNS
                </h4>
                <v-btn
                  v-for="item in nakaitabashiSnsItems"
                  :key="item.id"
                  :href="item.link"
                  class="mr-4"
                  :color="item.color"
                  icon
                >
                  <v-icon size="32px">
                    {{ item.icon }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Location",
  data: () => {
    return {
      nakaitabashiSnsItems: [
        {
          id: 0,
          icon: "mdi-instagram",
          link: "https://www.instagram.com/osakana_teruteru/?hl=ja",
          color: "instagram"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          link:
            "https://www.facebook.com/%E3%81%8A%E3%81%95%E3%81%8B%E3%81%AA%E3%81%A6%E3%82%8B%E3%81%A6%E3%82%8B%E4%B8%AD%E6%9D%BF%E6%A9%8B%E5%BA%97-100142095085412/",
          color: "facebook"
        },
        {
          id: 2,
          icon: "mdi-twitter",
          link: "https://twitter.com/osakana__teru",
          color: "twitter"
        }
      ],
      takeoutApps: [
        {
          id: 0,
          src: require("@/assets/common/uber_eats_icon.png"),
          link:
            "https://www.ubereats.com/jp/tokyo/food-delivery/%E3%81%8A%E3%81%95%E3%81%8B%E3%81%AA%E3%81%A6%E3%82%8B%E3%81%A6%E3%82%8B/x_Yg0AfMSm649V-hbzhH-w?pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMiVFMyU4MiU4NCVFMyU4MSU4RCVFMyU4MSVBOCVFMyU4MiU5MyVFMyU4MSVBNiVFMyU4MiU4QiVFMyU4MSVBNiVFMyU4MiU4QiUyMCVFNiU5RCVCMSVFNCVCOCVBRCVFOSU4NyU4RSVFNSVCQSU5NyUyMiUyQyUyMnJlZmVyZW5jZSUyMiUzQSUyMkNoSUpDY21vN3JmeUdHQVJhelU0YjE0YnFnTSUyMiUyQyUyMnJlZmVyZW5jZVR5cGUlMjIlM0ElMjJnb29nbGVfcGxhY2VzJTIyJTJDJTIybGF0aXR1ZGUlMjIlM0EzNS43MDgxMTg0JTJDJTIybG9uZ2l0dWRlJTIyJTNBMTM5LjY4MjA0NjMlN0Q%3D"
        },
        {
          id: 1,
          src: require("@/assets/common/demae_kan.png"),
          link: "https://demae-can.com/shop/menu/3110656/"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  font-family: $footer-font-family;
}

.main-title {
  font-size: 2em;
  font-family: $main-font-family;
  @include display_pc {
    font-size: 3em;
  }
}

.banner-font-style {
  color: black;
  text-shadow: 2px 2px 5px white, -2px 2px 5px white, 2px -2px 5px white,
    -2px -2px 5px white;
}

.location {
  background-color: $osakana-main_color;
  padding-bottom: 50px;
}

.location-image {
  margin-left: 20px;
  @include display_smart_phone {
    margin-left: 0;
  }
}

.direction-reverse {
  @include display_smart_phone {
    flex-direction: column-reverse;
  }
}
.sub-title {
  margin-top: 8px;
}

.location-detail-color {
  color: white;
}

.phone-number {
  @include display_pc {
    pointer-events: none;
  }
}

h3 {
  color: $osakana_main_color;
  padding: 0.5em;
  display: inline-block;
  line-height: 1.3;
  background: $osakana_sub_color;
  vertical-align: middle;
  border-radius: 25px 0px 0px 25px;
  @include display_smart_phone {
    border: 3px solid white;
  }
}

h3:before {
  content: "●";
  color: white;
  margin-right: 8px;
}

.location-detail-wrapper {
  margin: 20px auto 20px 20px;
}

.osakana-location-map {
  border: 1px solid white !important;
  margin-top: 8px;
}

.store-picture {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
