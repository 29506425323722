<template>
  <v-container id="philosophy">
    <NavBar></NavBar>
    <Banner></Banner>
    <Main></Main>
    <Footer>
      <template v-slot:sns-share>
        <SnsShare></SnsShare>
      </template>
    </Footer>
  </v-container>
</template>

<script>
import Main from "@/components/Philosophy/Main";
import Banner from "@/components/Common/BrillerLogoBanner";
import Footer from "@/components/Common/Footer";
import NavBar from "@/components/Common/NavBar";
import SnsShare from "@/components/Philosophy/SnsShare";

export default {
  name: "Philosophy",
  components: {
    SnsShare,
    NavBar,
    Footer,
    Banner,
    Main
  }
};
</script>

<style scoped>
* {
  padding: 0;
}
</style>
