<template>
  <v-card class="sticky" id="briller-nav-bar">
    <v-toolbar flat absolute color="transparent" width="100%">
      <v-app-bar
        flat
        inverted-scroll
        scroll-threshold="80"
        class="show-pc-toolbar"
        color="brillerNavBar"
      >
        <v-toolbar-items class="show-pc">
          <v-btn
            v-for="item in navItems"
            :key="item.id"
            :href="item.link"
            text
            class="mx-4 nav-text"
          >
            {{ item.name }}
          </v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-app-bar
        flat
        inverted-scroll
        scroll-threshold="150"
        class="show-sp-toolbar"
        color="transparent"
      >
        <v-app-bar-nav-icon @click="drawer = true" class="show-smart-phone">
          <a flat color="transparent">
            <img
              :src="require('@/assets/briller/briller_logo_icon.png')"
              alt="Brillerの企業ロゴ"
              height="60"
              width="70"
              class="hamburger-icon"
            />
          </a>
        </v-app-bar-nav-icon>
      </v-app-bar>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" fixed temporary color="#424242">
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="item in navItems"
            :key="item.id"
            :href="item.link"
            text
            icon
          >
            <v-list-item-title class="nav-lists nav-text"
              >{{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  name: "NavBar",
  data: () => {
    return {
      drawer: false,
      navItems: [
        {
          id: 0,
          name: "HOME",
          link: "/"
        },
        {
          id: 1,
          name: "各店舗情報",
          link: "/#associated-location"
        },
        {
          id: 2,
          name: "企業活動",
          link: "/kodomo-syokudo"
        },
        {
          id: 3,
          name: "採用情報",
          link: "/job-description"
        },
        {
          id: 4,
          name: "経営理念",
          link: "/philosophy"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
}

#briller-nav-bar ::v-deep .v-toolbar__content {
  padding: 0;
}

.nav-text {
  font-weight: bold;
  color: white;
  font-size: 1.2em;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;
  opacity: 0.9;
}

.show-smart-phone {
  margin-top: 4px;
  @include display_pc {
    display: none;
  }
}

.hamburger-icon {
  margin-top: 3px;
  margin-left: 50px;
}

.show-pc-toolbar {
  display: none;
  @include display_tablet {
    display: none;
    @include display_pc {
      display: block;
    }
  }
}

.show-sp-toolbar {
  display: block;
  @include display_tablet {
    display: block;
    @include display_pc {
      display: none;
    }
  }
}

.show-pc {
  display: none;
  @include display_pc {
    display: block;
  }
}

.nav-lists {
  font-weight: bold;
  color: white;
  font-size: 1.2em !important;
}
</style>
