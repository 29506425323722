<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-btn
        to="/contact"
        elevation="2"
        color="transparent"
        class="contact-button"
      >
        <v-icon left class="contact-icon">mdi-chevron-double-right</v-icon>
        お問い合わせ
        <v-icon right class="contact-icon">mdi-chevron-double-left</v-icon>
      </v-btn>
    </v-col>
    <v-col class="contact-waning">
      ※ご予約に関しては直接店舗へ<br class="sp-br" />お問い合わせください。
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Contact"
};
</script>

<style lang="scss" scoped>
.contact-button {
  font-size: 1.5em;
  font-weight: bold;
  border: black solid 2px !important;
  padding: 25px !important;
  @include display_smart_phone {
    font-size: 1.2em;
  }
}

.contact-icon {
  background-color: transparent;
}

.sp-br {
  @include display_tablet {
    display: none;
  }
}

.contact-waning {
  margin: 15px auto;
}
</style>
