<template>
  <v-container id="osakana-main-message">
    <v-card
      class="introduce-title ml-auto"
      elevation="0"
      color="transparent"
      rounded="0"
    >
      <img
        :src="require('@/assets/OsakanaTeruteru/tuna.png')"
        alt="マグロが飛び跳ねるイラスト"
        height="50"
        class="tuna-illustration"
      />
      <h1>おさかなてるてる<br class="sp-br" />のこだわり</h1>
      <img
        :src="require('@/assets/OsakanaTeruteru/tuna.png')"
        alt="マグロが飛び跳ねるイラスト"
        height="50"
        class="tuna-illustration"
      />
    </v-card>
    <v-row class="main-message introduce-reverse" no-gutters>
      <v-col cols="12" sm="6" class="message-reverse-order-3">
        <v-card class="introduce-image">
          <v-img
            :src="require('@/assets/OsakanaTeruteru/tempura_teisyoku.jpg')"
            alt="さくっと軽い味わいの鮮魚の天ぷら定食の写真"
          >
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" class="message-reverse-order-1">
        <v-card class="introduce-message" elevation="0" color="transparent">
          おさかなてるてるでは、より新鮮な魚介をお客様の元へ届けるため
          豊洲市場や北海道から直に鮮魚を仕入れ、お店で丁寧にさばき下処理をした上で
          味はもちろん目でも楽しんで頂けるよう、魚にあわせた味付けや盛り付けを心がけご提供致します。
          取れたばかりの新鮮な魚や季節ごとでしか味わえない旬な魚、イキの良い高級魚など多数ご用意し、
          毎日来て頂いても飽きのこない豊富な品揃えです！その日によって品揃えが変わってきますので、是非店頭で
          その日の一押しをお選びください。
        </v-card>
      </v-col>
    </v-row>
    <v-row class="main-message introduce-reverse" no-gutters>
      <v-col cols="12" sm="6">
        <v-card class="introduce-message" elevation="0" color="transparent">
          トロッと溶ける脂の乗ったマグロやハマチを贅沢に厚切りにし、ふんだんに盛り込んだ海鮮丼、
          口に入れただけでほろりと溶ける白身魚の煮付けや、外はカリカリ中はふわっふわの唐揚げなど、
          仕入れた新鮮な魚や貝やエビに合わせたベストな調理法でお召し上がり頂けます。
          中でも、強火の藁で焼いたカツオはおさかなてるてるおすすめの一品です。
          テイクアウトメニューも充実しておりますので、是非お越しください！
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card flat class="introduce-image">
          <v-img
            :src="require('@/assets/OsakanaTeruteru/warayaki.jpeg')"
            alt="おさかなてるてる自慢のカツオの藁焼き"
          >
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MainMessage"
};
</script>

<style lang="scss" scoped>
.tuna-illustration {
  margin: auto 10px;
}

.main-message {
  margin: 30px 0px 25px;
}

.introduce-title {
  font-size: 1em;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 18px auto;
  -webkit-text-stroke: 1px $osakana_main_color;
  text-shadow: $osakana_sub_color 2px 2px 0px;
  border-bottom: $osakana-main_color 2px dashed !important;
  padding-bottom: 10px;
  width: 60%;
  @include display_tablet {
    font-size: 0.8em;
    text-shadow: $osakana_sub_color 3px 3px 0px;
  }
  @include display_smart_phone {
    font-size: 0.6em;
    width: 80%;
  }
}

.introduce-reverse {
  flex-direction: column-reverse;
  @include display_tablet {
    flex-direction: row;
  }
}

.introduce-message {
  line-height: 2em;
  margin: 20px;
  @include display_smart_phone {
    font-family: $messages-font-family;
  }
  @include display_pc {
    line-height: 2.5em;
  }
  @include display_pc_medium {
    font-size: 1.3em;
    line-height: 2em;
    margin: 20px 50px auto 50px;
  }
}

.message-reverse-wrap {
  display: flex;
}

.message-reverse-order-1 {
  order: 1;
  @include display_tablet {
    order: unset;
  }
}

.message-reverse-order-2 {
  order: 2;
  @include display_tablet {
    order: unset;
  }
}

.message-reverse-order-3 {
  order: 3;
  @include display_tablet {
    order: unset;
  }
}

.pc-br {
  @include display_smart_phone {
    display: none;
  }
}

.sp-br {
  @include display_pc {
    display: none;
  }
}
</style>
