import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend
} from "vee-validate";
import ja from "vee-validate/dist/locale/ja.json";
import { numeric, email, required } from "vee-validate/dist/rules";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

localize("ja", ja);

extend("numeric", numeric);
extend("email", email);
extend("required", required);

// custom validation
extend("hiragana", value => {
  const hiraganaReg = /^[ぁ-んー\u0020\u3000]+$/;
  if (hiraganaReg.test(value) && value.length < 32) {
    return true;
  }
  return "ひらがなで入力してください。(32文字以内)";
});

// ひらがな、カタカナ、漢字、space、全角space、英語を許可
extend("all_string", value => {
  const katakanaKanjiReg = /^[ぁ-んァ-ン一-龥a-zA-Zー\u3005-\u3006\u0020\u3000]+$/;
  if (katakanaKanjiReg.test(value) && value.length < 32) {
    return true;
  }
  return "漢字、ひらがな、カタカナ、アルファベットのみ入力可能です。(32文字以内)";
});

extend("cellphone", value => {
  const cellphoneReg = /^0[7-9]0[1-9]\d{3}\d{4}$/;
  if (cellphoneReg.test(value)) {
    return true;
  }
  return "正しい携帯番号を入力してください。（ハイフンなし）";
});
