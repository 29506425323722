<template>
  <v-container class="yakiton-banner">
    <v-card>
      <v-img
        :src="require('@/assets/YakitonTeruTeru/yakiton_banner.jpg')"
        alt="Brillerが経営するやきとんてるてるの店舗内装写真"
        class="banner-image"
        decoding="async"
      >
        <v-row class="fill-height ma-0" align="center" justify="center">
          <div class="pt-8 pb-8 pl-12 pr-12 banner">
            <h1 class="white--text">
              やきとん<br />
              てるてる
            </h1>
          </div>
        </v-row>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "TeruTeruBanner"
};
</script>

<style lang="scss" scoped>
.banner-image {
  height: $banner-height;
  width: $banner-width;
}

.banner {
  font-size: 1em;
  border: 4px solid white;
  @include display_pc {
    font-size: 1.2em;
    border: 4px solid white;
  }
}
</style>
