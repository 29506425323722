<template>
  <v-form id="apply-form">
    <v-row no-gutters>
      <v-col class="pa-0" cols="12">
        <v-card-text flat align="center" class="pa-0">
          <h2 class="apply-form-title">
            お問い合わせ<br class="sp-br" />送信完了
          </h2>
        </v-card-text>
        <v-card-text class="apply-messages">
          お問い合わせ頂きありがとうございます。<br />
          3〜4営業日程で弊社担当者よりご連絡をさせて頂きます。
          万が一、上記日数を過ぎましても返事がない場合は、お手数ですが改めて応募フォームまたは問い合わせフォームより
          ご連絡を頂きますようお願い致します。<br />
          ※ご返信につきましては、お問い合わせの数によりお時間を頂く場合があります。<br />
        </v-card-text>
        <v-btn to="/" class="back-button" color="transparent" elevation="1">
          <v-icon left>mdi-chevron-double-right</v-icon>
          トップページへ戻る
          <v-icon right>mdi-chevron-double-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "FinishContact"
};
</script>

<style lang="scss" scoped>
.apply-form-wrap {
  width: 75%;
  margin: 32px auto;
}

.apply-form-title {
  position: relative;
  font-weight: bold;
  line-height: 2.6rem;
  width: 50%;
  font-size: 1.5rem;
  margin: 25px auto;
  padding: 25px 8px;
  @include display_pc {
    font-size: 2rem;
  }
}

.apply-form-title:before,
.apply-form-title:after {
  content: "";
  width: 20px;
  height: 30px;
  position: absolute;
  display: inline-block;
}

.apply-form-title:before {
  border-left: solid 1px $briller_sub_color;
  border-top: solid 1px $briller_sub_color;
  top: 0;
  left: 0;
}

.apply-form-title:after {
  border-right: solid 1px $briller_sub_color;
  border-bottom: solid 1px $briller_sub_color;
  bottom: 0;
  right: 0;
}

.sp-br {
  @include display_tablet {
    display: none;
  }
}
.apply-messages {
  padding: 20px 20%;
  @include display_smart_phone {
    padding: 20px 10%;
  }
}

.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  margin: 40px auto;
  border: 2px solid black !important;
  font-size: 1.3em;
  font-weight: bold;
  padding: 25px !important;
  @include display_smart_phone {
    font-size: 1.2em;
    width: 80%;
  }
}
</style>
