<template>
  <v-container id="briller-carousel">
    <div id="carousel-slider" align="center">
      <v-carousel hide-delimiter-background height="300" class="carousel_inner">
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          :alt="item.alt"
          class="carousel-items"
        ></v-carousel-item>
      </v-carousel>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      items: [
        {
          src: require("@/assets/OsakanaTeruteru/sashimi_teisyokujpg.jpg"),
          alt: "おさかなてるてる人気の鮮魚のお刺身定食の写真"
        },
        {
          src: require("@/assets/OsakanaTeruteru/tempura_teisyoku.jpg"),
          alt: "おさかなてるてる一押しの天ぷら定食の写真"
        },
        {
          src: require("@/assets/OsakanaTeruteru/kaisen_don.jpg"),
          alt: "お持ち帰り用大人気海鮮丼の写真"
        },
        {
          src: require("@/assets/OsakanaTeruteru/chirashi_don.jpg"),
          alt: "お持ち帰り用定番海鮮チラシ丼の写真"
        },
        {
          src: require("@/assets/OsakanaTeruteru/inside_osakana.jpg"),
          alt: "おさかなてるてるの内装写真"
        },
        {
          src: require("@/assets/OsakanaTeruteru/outside_osakana.jpg"),
          alt: "おさかなてるてるの外装写真"
        },
        {
          src: require("@/assets/OsakanaTeruteru/filletting_mackerel.jpg"),
          alt: "サバのおろしの写真"
        },
        {
          src: require("@/assets/OsakanaTeruteru/fish_cutting.jpg"),
          alt: "新鮮な魚を丁寧に捌く写真"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
#carousel-slider {
  margin: 15px auto;
  width: 60%;
  @include display_smart_phone {
    width: 100%;
  }
}
</style>
