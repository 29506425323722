<template>
  <v-container id="introduce">
    <v-row class="yakiton-introduce introduce-reverse" no-gutters>
      <v-col cols="12" sm="6" class="message-reverse-order-3">
        <v-card flat class="introduce-image">
          <v-img
            :src="require('@/assets/YakitonTeruTeru/yakiton_drink_picture.jpg')"
            alt="やきとん、焼き鳥、ビールやチューハイが並んだ写真"
            decoding="async"
          >
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" class="message-reverse-order-1">
        <v-card
          class="vertical-text ml-auto introduce-first-message"
          elevation="0"
          color="transparent"
        >
          「ちょっと、一杯」を気軽にできるのが<br class="pc-br" />
          大衆居酒屋の最大の魅力。<br class="pc-br" />
          今日も一日お疲れ様。と乾杯の挨拶も早々にビールを一口。<br />
          お客様とスタッフが一体となり作り出す活気や賑やかさ、<br
            class="pc-br"
          />
          たわいも無い話で、一日の疲れを笑いに変えれる<br class="pc-br" />
          アットホームな雰囲気がいつもここに。<br class="pc-br" />
          それが<strong>やきとんてるてる</strong>の大きな魅力の一つです。<br
            class="pc-br"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="2" class="message-reverse-order-2">
        <v-card
          class="vertical-text introduce-title ml-auto"
          elevation="0"
          rounded="0"
          color="transparent"
        >
          <h2>
            大衆居酒屋にしかない<br />
            居心地をやきとんてるてるで
          </h2>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="yakiton-introduce introduce-reverse" no-gutters>
      <v-col cols="12" sm="4">
        <v-card
          class="vertical-text ml-auto introduce-second-message"
          elevation="0"
          color="transparent"
        >
          やきとんてるてるでは、新鮮な食材で作る食事を<br class="pc-br" />
          皆様に提供できるよう毎日新鮮な魚や肉を仕入れ<br class="pc-br" />
          その日のうちに下処理をしお客様へ提供しています。<br class="pc-br" />
          仕入れ、仕込み、盛り付けなど、すべてに工夫やこだわりを<br
            class="pc-br"
          />
          ふんだん盛り込んでいます。<br class="pc-br" />
          ほろりと口の中でほどけるモツ煮込み、<br class="pc-br" />
          炭火でじっくりと焼いた やきとん、焼き鳥は<br class="pc-br" />
          香ばしいかおりが口いっぱいに広がる<br class="pc-br" />
          てるてるのおすすめの一品です。<br class="pc-br" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="2">
        <v-card
          class="vertical-text introduce-title ml-auto"
          elevation="0"
          color="transparent"
        >
          <h2>
            やきとんてるてるの<br />
            食へのこだわり
          </h2>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card flat class="introduce-image">
          <v-img
            :src="require('@/assets/YakitonTeruTeru/yakiton_picture.jpg')"
            alt="やきとんを炭火で焼く写真"
            decoding="async"
          >
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Introduce"
};
</script>

<style lang="scss" scoped>
.yakiton-introduce {
  margin: 30px 12px 25px;
}

.yakiton-introduce-picture {
  width: 490px;
  height: 100%;
}

.introduce-title {
  font-size: 1.2em;
  font-weight: normal;
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 18px auto;
  color: #003740;
  text-shadow: #d4b572 3px 3px 1px;
  @include display_smart_phone {
    font-size: 1em;
  }
}

.introduce-reverse {
  flex-direction: column-reverse;
  @include display_tablet {
    flex-direction: row;
  }
}

.vertical-text {
  writing-mode: horizontal-tb;
  line-height: 1.5em;
  @include display_tablet {
    writing-mode: vertical-rl;
    line-height: 2em;
  }
}

.introduce-first-message {
  line-height: 2em;
  @include display_tablet {
    line-height: 2em;
  }
  @include display_pc {
    line-height: 2.5em;
  }
}

.introduce-second-message {
  line-height: 2em;
  @include display_tablet {
    line-height: 1.4em;
  }
  @include display_pc {
    line-height: 2em;
  }
}

.message-reverse-wrap {
  display: flex;
}

.message-reverse-order-1 {
  order: 1;
  @include display_tablet {
    order: unset;
  }
}

.message-reverse-order-2 {
  order: 2;
  @include display_tablet {
    order: unset;
  }
}

.message-reverse-order-3 {
  order: 3;
  @include display_tablet {
    order: unset;
  }
}

.pc-br {
  @include display_smart_phone {
    display: none;
  }
}
</style>
