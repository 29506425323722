<template>
  <v-footer padless id="briller-footer">
    <v-card flat tile class="text-center" width="100%">
      <ContactButton class="override-contact-button"></ContactButton>
      <v-card-text>
        <ShareNetwork
          network="twitter"
          url="https://briller-jp.com/briller"
          title="飲食店経営会社Briller(ブルイエ)の公式ページをシェア！大衆居酒屋から定食屋まで飲食店店舗を多数経営！"
          description="飲食店経営会社Briller(ブルイエ)の公式ページをシェア！大衆居酒屋から定食屋まで飲食店店舗を多数経営！"
          hashtags="合同会社Briller,ブルイエ,てるてる,高田馬場,東中野,ひばりヶ丘,中板橋,江古田,大衆居酒屋,定食屋,飲食店,鮮魚専門店"
          class="mr-4"
        >
          <v-btn color="twitter" class="white--text">
            {{ snsItems[0].message }}
            <v-icon class="sns-icons white--text">
              {{ snsItems[0].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
        <ShareNetwork
          network="facebook"
          url="https://briller-jp.com/briller"
          title="飲食店経営会社Briller(ブルイエ)の公式ページをシェア！大衆居酒屋から定食屋まで飲食店店舗を多数経営！"
          description="飲食店経営会社Briller(ブルイエ)の公式ページをシェア！大衆居酒屋から定食屋まで飲食店店舗を多数経営！"
          quote="飲食店経営会社Briller(ブルイエ)の公式ページをシェア！大衆居酒屋から定食屋まで飲食店店舗を多数経営！"
          hashtags="飲食店経営会社Brillerブルイエ"
          class="mr-4"
        >
          <v-btn color="facebook" class="white--text">
            {{ snsItems[1].message }}
            <v-icon class="sns-icons white--text">
              {{ snsItems[1].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
      </v-card-text>
      <CompanyInformation class="company-info"></CompanyInformation>
      <CopyRight></CopyRight>
    </v-card>
  </v-footer>
</template>

<script>
import CompanyInformation from "@/components/Common/CompanyInformation";
import CopyRight from "@/components/Common/CopyRight";
import ContactButton from "@/components/Common/ContactButton";

export default {
  name: "Footer",
  components: { ContactButton, CopyRight, CompanyInformation },
  data: () => {
    return {
      snsItems: [
        {
          id: 0,
          icon: "mdi-twitter",
          message: "ツイート"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          message: "シェアする"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  font-family: $footer-font-family;
  background-color: #281a14;
  color: white;
}

a {
  text-decoration: none;
}

.override-contact-button ::v-deep .contact-button {
  margin-top: 50px;
  color: white;
  border: white solid 2px !important;
}

.sns-icons {
  background-color: transparent;
}

.company-info ::v-deep .theme--light.v-sheet {
  color: white;
}
</style>
