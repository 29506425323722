<template>
  <v-container class="kodomo-syokudo-banner">
    <v-card>
      <v-img
        :src="require('@/assets/KodomoSyokudo/kodomo_syokudo.png')"
        alt="ブルイエが活動するこども食堂用バナー画像"
        class="banner-image"
      >
        <v-row class="fill-height ma-0" align="center" justify="start">
          <div class="activity-banner">
            <h1 class="black--text">
              企業活動
            </h1>
          </div>
        </v-row>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Banner"
};
</script>

<style lang="scss" scoped>
.banner-image {
  height: $banner-height;
  width: $banner-width;
  @include display_pc {
    height: 400px;
  }
}

.activity-banner {
  font-size: 1em;
  border: 3px solid black;
  margin-left: 20px;
  padding: 13px 56px;
  @include display_pc {
    font-size: 1.5em;
    border: 3px solid black;
    margin-left: 100px;
    width: 40%;
    display: flex;
    justify-content: center;
  }
  @include display_smart_phone {
    padding: 13px 25px;
  }
}
</style>
