<template>
  <v-container id="philosophy-sns">
    <v-card flat tile class="text-center" width="100%">
      <v-card-text class="pb-0">
        <ShareNetwork
          network="twitter"
          url="https://briller-jp.com/philosophy"
          title="都内多数飲食店店舗を運営する合同会社Briller(ブルイエ)の経営理念をシェア！"
          description="都内多数飲食店店舗を運営する合同会社Briller(ブルイエ)の経営理念"
          hashtags="合同会社Briller,ブルイエ,経営理念,大衆居酒屋,エスニック料理店,鮮魚専門店,定食屋,食堂,やきとん,モツ煮込み"
          class="mr-4"
        >
          <v-btn color="twitter">
            {{ snsItems[0].message }}
            <v-icon class="sns-icons">
              {{ snsItems[0].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
        <ShareNetwork
          network="facebook"
          url="https://briller-jp.com/philosophy"
          title="都内多数飲食店店舗を運営する合同会社Briller(ブルイエ)の経営理念をシェア"
          description="都内多数飲食店店舗を運営する合同会社Briller(ブルイエ)の経営理念！心を込めて飲食店運営！"
          quote="都内多数飲食店店舗を運営する合同会社Briller(ブルイエ)の経営理念！"
          hashtags="飲食店経営会社Brillerブルイエ経営理念"
          class="mr-4"
        >
          <v-btn color="facebook">
            {{ snsItems[1].message }}
            <v-icon class="sns-icons">
              {{ snsItems[1].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "SnsShare",
  data: () => {
    return {
      snsItems: [
        {
          id: 0,
          icon: "mdi-twitter",
          message: "ツイート"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          message: "シェアする"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  font-family: $footer-font-family;
  color: white !important;
  text-decoration: none;
}
</style>
