<template>
  <v-container id="activity-detail">
    <v-row no-gutters>
      <v-col class="pa-0">
        <v-card-text
          flat
          class="activity-main-title rounded-0 my-8"
          align="center"
          ><h2>こども食堂<br class="sp-br" />活動詳細</h2>
        </v-card-text>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-card>
          <v-img
            :src="require('@/assets/KodomoSyokudo/kodomo_syokudo.jpg')"
            alt="こども食堂のイメージ写真"
          >
          </v-img>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="activity-messages" align="center" elevation="0">
          <h3 class="justify-center syokudo-detail-title">
            開催詳細
          </h3>
          <v-card-text>
            <v-row>
              <v-col class="kodomo-syokudo-item" cols="12" sm="4" md="3" lg="4">
                開催日程
              </v-col>
              <v-col class="kodomo-syokudo-info kodomo-syokudo-date">
                第３日曜日：やきとんてるてる各店舗<br />
                準備中：まるてる食堂<br />
                <span class="kodomo-syokudo-info-note"
                  >※日程は変更する可能性がございます。詳しくは各店舗へお問い合わせ下さい。</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="kodomo-syokudo-item" cols="12" sm="4" md="3" lg="4">
                参加費
              </v-col>
              <v-col class="kodomo-syokudo-info">
                無料
              </v-col>
            </v-row>
            <v-row>
              <v-col class="kodomo-syokudo-item" cols="12" sm="4" md="3" lg="4">
                開催場所
              </v-col>
              <v-col class="kodomo-syokudo-info">
                ブルイエ運営お食事処各店舗<br />詳しくは下記店舗情報をご確認ください。
              </v-col>
            </v-row>
            <v-row>
              <v-col class="kodomo-syokudo-item" cols="12" sm="4" md="3" lg="4">
                店舗情報
              </v-col>
              <v-col class="kodomo-syokudo-info">
                <router-link to="/yakiton-teruteru" class="location-info">
                  やきとんてるてる(高田馬場店/東中野店/ひばりヶ丘店/江古田店)<br
                /></router-link>
                <router-link to="#" class="location-info">
                  まるてる食堂江古田店<br />
                </router-link>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ActivityDetail"
};
</script>

<style lang="scss" scoped>
* {
  color: black;
}
.activity-main-title {
  font-weight: bold;
  margin: 0 auto;
  line-height: 2em;
  padding-bottom: 12px;
  font-size: 1rem;
  width: 50%;
  background: -webkit-repeating-linear-gradient(
    -45deg,
    #fff5df,
    #fff5df 4px,
    #ffe4b1 3px,
    #ffe4b1 8px
  );
  background: repeating-linear-gradient(
    -45deg,
    #fff5df,
    #fff5df 4px,
    #ffe4b1 3px,
    #ffe4b1 8px
  );
  border-radius: 7px;
  @include display_pc {
    margin-bottom: 18px;
  }
}

.syokudo-detail-title {
  font-weight: bold;
  border-bottom: 1px solid black;
  width: 50%;
  margin-bottom: 30px;
  padding: 5px;
}

.sp-br {
  @include display_pc {
    display: none;
  }
}

.activity-messages {
  padding: 16px;
  margin: auto 20px;
  @include display_pc {
    margin: auto 10px;
  }
}

.kodomo-syokudo-item {
  text-align: center;
  font-weight: bold;
  font-size: 1.3em;
  @include display_pc {
    text-align: left;
  }
}

.kodomo-syokudo-info {
  text-align: left;
  font-size: 1.3em;
}

.kodomo-syokudo-date {
  line-height: 1.7em;
}

.location-info {
  color: black;
  text-decoration: none;
  border-bottom: 1px solid black;
  line-height: 1.7em;
}

.kodomo-syokudo-info-note {
  font-size: 0.7em;
}
</style>
