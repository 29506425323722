<template>
  <v-container id="yakiton-teruteru-menu">
    <v-card>
      <v-img
        :src="require('@/assets/YakitonTeruTeru/yakiton_banner.jpg')"
        alt="やきとんてるてる店舗内装写真"
        decoding="async"
        height="230"
        width="100%"
        class="image-relative"
      >
        <v-img
          :src="require('@/assets/YakitonTeruTeru/takeout-message.png')"
          alt="テイクアウトメニューも充実"
          decoding="async"
          class="image-absolute"
        >
        </v-img>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <div class="pt-8 pb-8 pl-12 pr-12">
            <h2
              class="font-weight-light white--text font-weight-bold main-title"
            >
              お品書き
            </h2>
            <div
              class="subtitle-1 white--text font-weight-bold text-decoration-underline"
              align="center"
            >
              MENU
            </div>
          </div>
        </v-row>
      </v-img>
    </v-card>
    <v-card-text align="center">
      ※表示価格は全て税抜き価格です。<br />
      ※メニューは一部抜粋しております。<br />
      ※品切れやメニュー変更などの理由からご提供一時停止する場合もございます。<br />
    </v-card-text>
    <v-row class="mb-6" no-gutters>
      <v-col class="menu-list" cols="12" sm="6" md="6" lg="6">
        <h3 class="menu-list-title" align="center" justify="center">
          料理
        </h3>
        <v-treeview
          dense
          open-on-click
          :items="menu.foodList"
          class="lists"
        >
          <template slot="label" slot-scope="{ item }">
            <div class="annotation-style">
              {{ item.message }}
            </div>
            <v-row>
              <v-col>
                {{ item.name }}
              </v-col>
              <v-col align="right">
                {{ item.price }}
              </v-col>
            </v-row>
          </template>
        </v-treeview>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6">
        <h3 class="menu-list-title" align="center" justify="center">
          ドリンク
        </h3>
        <v-treeview dense open-on-click :items="menu.drinkList" class="lists">
          <template slot="label" slot-scope="{ item }">
            <div class="annotation-style">
              {{ item.message }}
            </div>
            <v-row>
              <v-col>
                {{ item.name }}
              </v-col>
              <v-col align="right">
                {{ item.price }}
              </v-col>
            </v-row>
          </template>
        </v-treeview>
        <div class="annotation-style" align="center">
          ※各店舗でワインも取り揃えております。<br />
          詳しくは、店舗へご確認ください。
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-6" no-gutters>
      <v-col cols="12" sm="6" md="6" lg="6">
        <h3 class="menu-list-title" align="center" justify="center">
          お持ち帰り
        </h3>
        <v-treeview
          dense
          open-on-click
          :items="menu.takeoutList"
          class="lists"
        >
          <template slot="label" slot-scope="{ item }">
            <v-row>
              <v-col>
                {{ item.name }}
              </v-col>
              <v-col align="right">
                {{ item.price }}
              </v-col>
            </v-row>
          </template>
        </v-treeview>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
        <h3 class="menu-list-title" align="center" justify="center">
          ランチ
        </h3>
        <v-treeview
          dense
          open-on-click
          :items="menu.lunchList"
          class="lists"
        >
          <template slot="label" slot-scope="{ item }">
            <v-row>
              <v-col>
                {{ item.name }}
              </v-col>
              <v-col align="right">
                {{ item.price }}
              </v-col>
            </v-row>
          </template>
        </v-treeview>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import menu from "@/json/menu/yakitonTeruTeru.json"

export default {
  name: "Menu",
  data: () => ({
    menu
  })
};
</script>

<style lang="scss" scoped>
.main-title {
  font-size: 2em;
  @include display_pc {
    font-size: 3em;
  }
}

.menu-list-title {
  font-size: 25px;
  margin: 40px auto;
  border-bottom: 2px solid rgb(51 39 35);
  width: 50%;
  @include display_pc {
    font-size: 25px;
    margin: 40px auto;
    border-bottom: 2px solid rgb(51 39 35);
    width: 40%;
  }
}

.annotation-style {
  font-size: 13px;
}

.lists {
  font-size: 1em;
  margin-left: 35px;
  @include display_pc {
    font-size: 1.2em;
    margin-left: 18%;
  }
}

.menu-png-picture {
  height: 180px;
  widh: 180px;
  @include display_tablet {
    margin-left: 32px;
    height: 150px;
    widh: 150px;
  }
  @include display_pc {
    margin-left: 23px;
    height: 200px;
    widh: 200px;
  }
}

.takeout-picture {
  margin-left: 45%;
}

.image-absolute {
  position: absolute;
  left: 3%;
  top: 3%;
  height: 85px;
  width: 125px;
  @include display_pc {
    position: absolute;
    left: 15%;
    top: 20px;
    height: 150px;
    width: 180px;
  }
}

.image-relative {
  position: relative;
}
</style>
