<template>
  <v-container>
    <NavBar></NavBar>
    <Banner></Banner>
    <router-view />
    <Footer></Footer>
  </v-container>
</template>

<script>
import Banner from "@/components/Common/BrillerLogoBanner";
import Footer from "@/components/Contact/Footer";
import NavBar from "@/components/Common/NavBar";

export default {
  name: "ContactPage",
  components: {
    NavBar,
    Footer,
    Banner
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  background-color: $main-background-color;
}
</style>
