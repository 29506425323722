<template>
  <v-form id="apply-form">
    <v-row no-gutters>
      <v-col class="pa-0">
        <v-card-text flat align="center" class="pa-0">
          <h2 class="apply-form-title">求人<br class="sp-br" />応募フォーム</h2>
        </v-card-text>
      </v-col>
    </v-row>

    <div class="apply-form-wrap">
      <validation-observer ref="obs" v-slot="ObserverProps">
        <v-row class="div-wrap-username">
          <v-col class="form-item">
            <validation-provider
              rules="required|all_string"
              name="名前"
              v-slot="{ errors }"
            >
              <v-text-field
                type="text"
                id="name"
                for="name"
                :error-messages="errors"
                label="お名前（フルネーム）"
                v-model="applicant.name"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="div-wrap-username">
          <v-col class="form-item">
            <validation-provider
              rules="required|hiragana"
              name="ふりがな"
              v-slot="{ errors }"
            >
              <v-text-field
                type="text"
                id="kana_name"
                for="kana_name"
                :error-messages="errors"
                label="ふりがな（ひらがなフルネーム）"
                v-model="applicant.kanaName"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="div-wrap-phone">
          <v-col class="form-item">
            <validation-provider
              rules="required|cellphone"
              name="電話番号"
              v-slot="{ errors }"
            >
              <v-text-field
                type="tel"
                id="phone"
                for="phone"
                :error-messages="errors"
                v-model="applicant.phone"
                label="電話番号（ハイフンなし）"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="div-wrap-email">
          <v-col class="form-item">
            <validation-provider
              rules="email"
              name="メールアドレス"
              v-slot="{ errors }"
            >
              <v-text-field
                for="email"
                type="email"
                id="email"
                :error-messages="errors"
                v-model="applicant.email"
                label="メールアドレス"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="div-wrap-gender">
          <v-col class="form-item">
            <validation-provider
              rules="required"
              name="性別"
              v-slot="{ errors }"
            >
              <v-radio-group v-model="applicant.gender" row>
                <span class="gender">性別</span>
                <v-radio
                  label="男"
                  value="male"
                  type="radio"
                  id="male"
                  color="#263238"
                  :error-messages="errors"
                ></v-radio>
                <v-radio
                  label="女"
                  value="female"
                  type="radio"
                  id="female"
                  color="#263238"
                ></v-radio>
              </v-radio-group>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="div-wrap-memo">
          <v-col class="form-item">
            <validation-provider name="備考欄" v-slot="{ errors }">
              <v-textarea
                for="memo"
                id="memo"
                :error-messages="errors"
                v-model="applicant.memo"
                label="備考欄（オプション） 例：平日22時まで勤務希望"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <vue-recaptcha
          ref="recaptcha"
          :sitekey="$config.recaptchaSiteKey"
          @verify="onVerify"
          @expired="onExpired"
        >
          <v-btn
            @click="checkRobot()"
            :disabled="ObserverProps.invalid"
            class="submit-button white--text"
            elevation="2"
            color="brillerNavBar"
          >
            <Loading v-show="loading"></Loading>
            応募する
          </v-btn>
        </vue-recaptcha>
      </validation-observer>
    </div>
  </v-form>
</template>

<script>
import Applicant from "@/models/applicant";
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
import "@/plugins/vee-validate";
import Loading from "@/components/Common/Loading";

export default {
  name: "ApplyForm",
  data() {
    return {
      applicant: new Applicant("", "", "", "", "", "", ""),
      successful: false,
      loading: false
    };
  },
  components: {
    VueRecaptcha,
    Loading
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    handleApplyForm() {
      const data = new URLSearchParams();
      data.append("name", this.applicant.name);
      data.append("kanaName", this.applicant.kanaName);
      data.append("phone", this.applicant.phone);
      data.append("email", this.applicant.email);
      data.append("gender", this.applicant.gender);
      data.append("memo", this.applicant.memo);
      data.append("robotToken", this.applicant.robotToken);

      this.loading = true;
      const options = {
        method: "POST",
        url:
          "https://briller-build-run-api-njeuz4cpyq-an.a.run.app/api/applicant/apply",
        data: data
      };

      axios(options)
        .then(() => {
          this.$router.push({ path: "/apply-form/finish" });
        })
        .catch(error => {
          console.log(error);
        });
    },
    onVerify(res) {
      this.applicant.robotToken = res;
      this.$refs.recaptcha.reset();
      this.handleApplyForm();
    },
    onExpired() {
      this.applicant.robotToken = "";
      this.$refs.recaptcha.reset();
    },
    checkRobot() {
      this.$refs.recaptcha.execute();
    }
  }
};
</script>

<style lang="scss">
.sp-br {
  @include display_pc {
    display: none;
  }
}
.apply-form-wrap {
  width: 75%;
  margin: 32px auto;
}

.apply-form-title {
  position: relative;
  font-weight: bold;
  line-height: 2.6rem;
  width: 50%;
  font-size: 1.5rem;
  margin: 25px auto;
  padding: 25px 8px;
  @include display_pc {
    font-size: 2rem;
  }
}

.apply-form-title:before,
.apply-form-title:after {
  content: "";
  width: 20px;
  height: 30px;
  position: absolute;
  display: inline-block;
}

.apply-form-title:before {
  border-left: solid 1px $briller_sub_color;
  border-top: solid 1px $briller_sub_color;
  top: 0;
  left: 0;
}

.apply-form-title:after {
  border-right: solid 1px $briller_sub_color;
  border-bottom: solid 1px $briller_sub_color;
  bottom: 0;
  right: 0;
}

.form-item {
  font-size: 1.3em;
}

.gender {
  margin-right: 32px;
}

.submit-button {
  width: 70%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8% auto;
  font-size: 1rem;
  @include display_pc {
    font-size: 1.7rem;
    height: 60px;
    width: 50%;
  }
}
</style>
