<template>
  <v-container id="corporate-activity">
    <v-row class="mb-6 flame-test" no-gutters>
      <v-col class="flame-test-sub">
        <v-col>
          <v-card
            class="pa-4 mx-10 activity-messages"
            align="center"
            elevation="0"
            color="transparent"
          >
            ブルイエ各店舗では<br />こども食堂を<br class="sp-br" />定期開催中！
          </v-card>
        </v-col>
        <v-col class="pt-0">
          <v-card-text align="center" justify="center" class="pt-0">
            <v-btn
              to="/Kodomo-Syokudo"
              class="white--text link-detail"
              color="brillerSubColor"
            >
              詳しくはこちら
              <v-icon>mdi-chevron-double-right</v-icon>
            </v-btn>
          </v-card-text>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CorporateActivity"
};
</script>

<style lang="scss" scoped>
.activity-messages {
  font-size: 2rem;
  @include display_smart_phone {
    font-size: 1.3rem;
  }
}

.sp-br {
  @include display_pc {
    display: none;
  }
}

.link-detail {
  font-size: 1.5rem;
  @include display_smart_phone {
    font-size: 1.3rem;
  }
}

.flame-test {
  margin: 2em auto;
  position: relative;
  padding: 0.25em 0.2em;
  border: solid 2px $briller_sub_color;
  border-radius: 3px 0 3px 0;
  width: 70%;
  @include display_smart_phone {
    width: 90%;
  }
}
.flame-test:before,
.flame-test:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: solid 2px $briller_sub_color;
  border-radius: 50%;
}
.flame-test:after {
  top: -12px;
  left: -12px;
}
.flame-test:before {
  bottom: -12px;
  right: -12px;
}
</style>
