<template>
  <v-card class="sticky" id="yakiton-nav-bar">
    <v-toolbar flat absolute color="transparent" width="100%">
      <v-app-bar
        flat
        inverted-scroll
        scroll-threshold="150"
        class="show-pc-toolbar"
        color="yakitonMainColor"
      >
        <v-toolbar-items class="show-pc">
          <v-btn
            v-for="item in navItems"
            :key="item.id"
            :href="item.link"
            text
            class="mx-4 yakiton-nav-text"
          >
            {{ item.name }}
          </v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
      </v-app-bar>

      <v-app-bar
        flat
        inverted-scroll
        scroll-threshold="150"
        class="show-sp-toolbar"
        color="transparent"
      >
        <v-app-bar-nav-icon @click="drawer = true" class="show-smart-phone">
          <v-btn class="hamburger-icon" fab dark color="yakitonSubColor">
            <v-icon dark>
              mdi-format-list-bulleted-square
            </v-icon>
          </v-btn>
        </v-app-bar-nav-icon>
      </v-app-bar>
    </v-toolbar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      color="yakitonMainColor"
    >
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="item in navItems"
            :key="item.id"
            :href="item.link"
          >
            <v-list-item-title class="nav-lists yakiton-nav-text">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  name: "NavBar",
  data: () => {
    return {
      drawer: false,
      navItems: [
        {
          id: 0,
          name: "店舗情報",
          link: "/yakiton-teruteru#yakiton-teruteru-location"
        },
        {
          id: 1,
          name: "お品書き",
          link: "/yakiton-teruteru#yakiton-teruteru-menu"
        },
        {
          id: 2,
          name: "採用情報",
          link: "/job-description"
        },
        {
          id: 3,
          name: "こども食堂開催中！",
          link: "/kodomo-syokudo"
        },
        {
          id: 4,
          name: "運営会社",
          link: "/"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
}

#yakiton-nav-bar ::v-deep .v-toolbar__content {
  padding: 0;
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 50;
  opacity: 0.9;
}

.hamburger-icon {
  margin-top: 2px;
  margin-left: 50px;
}

.show-pc-toolbar {
  display: none;
  @include display_tablet {
    display: none;
  }
  @include display_pc {
    display: block;
  }
}

.show-sp-toolbar {
  display: block;
  @include display_tablet {
    display: block;
  }
  @include display_pc {
    display: none;
  }
}

.yakiton-nav-text {
  font-weight: bold;
  color: $yakiton_sub_color;
  font-size: 1em;
}

.show-smart-phone {
  @include display_pc {
    display: none;
  }
}

.show-pc {
  display: none;
  @include display_pc {
    display: block;
  }
}

.nav-lists {
  font-weight: bold;
  color: $yakiton_sub_color;
  font-size: 1.2em !important;
}
</style>
