<template>
  <v-container id="yakiton-teruteru">
    <nav-bar />
    <banner />
    <introduce />
    <location />
    <Menu />
    <Carousel />
    <EmployeeRecruitment />
    <!--    <News />-->
    <Footer />
  </v-container>
</template>

<script>
import Footer from "@/components/YakitonTeruTeru/Footer";
import Banner from "@/components/YakitonTeruTeru/Banner";
import NavBar from "@/components/YakitonTeruTeru/NavBar";
import Introduce from "@/components/YakitonTeruTeru/Introduce";
import Menu from "@/components/YakitonTeruTeru/Menu";
import Location from "@/components/YakitonTeruTeru/Location";
// import News from "@/components/YakitonTeruTeru/News";
import EmployeeRecruitment from "@/components/YakitonTeruTeru/EmployeeRecruitment";
import Carousel from "@/components/YakitonTeruTeru/Carousel";

export default {
  name: "TeruTeru",
  components: {
    Carousel,
    Footer,
    Banner,
    NavBar,
    Introduce,
    Menu,
    Location,
    // News,
    EmployeeRecruitment
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  background-color: $main-background-color;
}
</style>
