<template>
  <v-container id="maruteru">
    <NavBar></NavBar>
    <Banner></Banner>
    <MainMessage></MainMessage>
    <Location></Location>
    <Menu></Menu>
    <Carousel></Carousel>
    <EmployeeRecruitment></EmployeeRecruitment>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Banner from "@/components/Maruteru/Banner";
import MainMessage from "@/components/Maruteru/MainMessage";
import Location from "@/components/Maruteru/Location";
import Menu from "@/components/Maruteru/Menu";
import Carousel from "@/components/Maruteru/Carousel";
import EmployeeRecruitment from "@/components/Maruteru/EmployeeRecruitment";
import Footer from "@/components/Maruteru/Footer";
import NavBar from "@/components/Maruteru/NavBar";

export default {
  name: "Maruteru",
  components: {
    NavBar,
    Footer,
    EmployeeRecruitment,
    Carousel,
    Menu,
    Location,
    MainMessage,
    Banner
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  background-color: $main-background-color;
}
</style>
