<template>
  <v-footer padless id="osakana-footer">
    <v-card flat tile class="text-center" width="100%">
      <ContactButton class="override-contact-button"></ContactButton>
      <v-card-text>
        <ShareNetwork
          network="twitter"
          url="https://briller-jp.com/osakana-teruteru"
          title="おさかなてるてる中板橋店公式ページをシェア！豊洲や北海道から仕入れた新鮮な魚料理多数！"
          description="豊洲や北海道から仕入れた新鮮な魚を取り扱うおさかなてるてるのページシェア！"
          hashtags="おさかなてるてる,中板橋,鮮魚,お刺身,海鮮丼,鮮魚専門店,定食,豊洲市場から仕入れ,北海道産"
          class="mr-4"
        >
          <v-btn color="twitter">
            {{ snsItems[0].message }}
            <v-icon class="sns-icons">
              {{ snsItems[0].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
        <ShareNetwork
          network="facebook"
          url="https://briller-jp.com/osakana-teruteru"
          title="おさかなてるてる中板橋店公式ページをシェア！"
          description="豊洲や北海道から仕入れた新鮮な魚を取り扱うおさかなてるてるのページシェア！"
          quote="おさかなてるてる中板橋店公式ページをシェア！豊洲や北海道から仕入れた新鮮な魚料理多数！"
          hashtags="おさかなてるてる中板橋"
          class="mr-4"
        >
          <v-btn color="facebook">
            {{ snsItems[1].message }}
            <v-icon class="sns-icons">
              {{ snsItems[1].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
      </v-card-text>
      <CompanyInformation class="company-info"></CompanyInformation>
      <CopyRight></CopyRight>
    </v-card>
  </v-footer>
</template>

<script>
import CompanyInformation from "@/components/Common/CompanyInformation";
import CopyRight from "@/components/Common/CopyRight";
import ContactButton from "@/components/Common/ContactButton";

export default {
  name: "Footer",
  components: { ContactButton, CopyRight, CompanyInformation },
  data: () => {
    return {
      snsItems: [
        {
          id: 0,
          icon: "mdi-twitter",
          message: "ツイート"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          message: "シェアする"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  font-family: $footer-font-family;
  background-color: $osakana_main_color;
  color: white !important;
  text-decoration: none;
}

.override-contact-button ::v-deep .contact-button {
  margin-top: 50px;
  color: white;
  border: white solid 2px !important;
}

.sns-icons {
  background-color: transparent;
}

.company-info ::v-deep .theme--light.v-sheet {
  color: white;
}
</style>
