<template>
  <v-footer id="maruteru-footer">
    <v-card flat tile class="text-center" width="100%">
      <ContactButton class="override-contact-button"></ContactButton>
      <v-card-text>
        <ShareNetwork
          network="twitter"
          url="https://briller-jp.com/maruteru"
          title="まるてる食堂江古田店公式ページをシェア！定食と肴を同時に味わえる食堂！"
          description="定食や酒の肴を味わえるまるてる食堂公式ページをシェア！"
          hashtags="まるてる食堂,江古田,モツ煮込み,デカ唐揚げ,お刺身,鮮魚,定食,食堂,豊洲市場"
          class="mr-4"
        >
          <v-btn color="twitter" class="white--text">
            {{ snsItems[0].message }}
            <v-icon class="sns-icons white--text">
              {{ snsItems[0].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
        <ShareNetwork
          network="facebook"
          url="https://briller-jp.com/maruteru"
          title="まるてる食堂江古田店公式ページをシェア！定食と肴を同時に味わえる食堂！"
          description="定食や酒の肴を味わえるまるてる食堂公式ページをシェア！"
          quote="定食や酒の肴を味わえるまるてる食堂公式ページをシェア！大人気モツ煮込み定食やデカ唐揚げ定食もおすすめ！"
          hashtags="まるてる食堂江古田"
          class="mr-4"
        >
          <v-btn color="facebook" class="white--text">
            {{ snsItems[1].message }}
            <v-icon class="sns-icons white--text">
              {{ snsItems[1].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
      </v-card-text>
      <CompanyInformation></CompanyInformation>
      <CopyRight></CopyRight>
    </v-card>
  </v-footer>
</template>

<script>
import CompanyInformation from "@/components/Common/CompanyInformation";
import CopyRight from "@/components/Common/CopyRight";
import ContactButton from "@/components/Common/ContactButton";

export default {
  name: "Footer",
  components: {
    ContactButton,
    CopyRight,
    CompanyInformation
  },
  data: () => {
    return {
      snsItems: [
        {
          id: 0,
          icon: "mdi-twitter",
          message: "ツイート"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          message: "シェアする"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  font-family: $footer-font-family;
  background-color: $maruteru_main_color;
  color: black;
}

a {
  text-decoration: none;
}

.override-contact-button ::v-deep .contact-button {
  margin-top: 50px;
}

.sns-icons {
  background-color: transparent;
}
</style>
