<template>
  <v-container id="yakiton-teruteru-location">
    <v-card class="mb-4">
      <v-img
        :src="require('@/assets/YakitonTeruTeru/yakiton_banner.jpg')"
        alt="やきとんてるてるの店舗内装写真"
        decoding="async"
        height="230"
        width="100%"
      >
        <v-row class="fill-height ma-0" align="center" justify="center">
          <div class="py-8 px-12">
            <h2
              class="font-weight-light white--text font-weight-bold main-title"
            >
              店舗情報
            </h2>
            <div
              class="subtitle-1 white--text font-weight-bold text-decoration-underline"
              align="center"
            >
              LOCATION
            </div>
          </div>
        </v-row>
      </v-img>
    </v-card>

    <v-row class="mb-6 location" no-gutters>
      <v-col cols="12" sm="6" md="6" lg="6" class="store-picture">
        <v-card>
          <v-img
            :src="require('@/assets/YakitonTeruTeru/takadanobaba.jpg')"
            alt="やきとんてるてる高田馬場店外装写真"
            decoding="async"
            class="store-picture-relative"
          ></v-img>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          class="pa-2 store-detail-wrapper vertical-text"
          elevation="0"
          color="transparent"
        >
          <div class="title-relative">
            <h3 class="sub-title title-absolute">
              高田馬場店
            </h3>
            <div class="location-border">
              <h4 class="sub-title">
                店舗住所
              </h4>
              <div>
                東京都新宿区高田馬場3丁目22-4
              </div>

              <h4 class="sub-title">
                アクセス
              </h4>
              <div>
                山手線高田馬場駅より徒歩7分 <br />
                <v-btn
                  href="https://g.page/yakitonteruteru1?share"
                  target="_blank"
                  rel="noopener"
                  elevation="0"
                  color="transparent"
                  class="yakiton-location-map"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                  Googleマップで開く</v-btn
                >
              </div>
              <h4 class="sub-title">
                営業時間
              </h4>
              <div>
                平日・土日：11:30 - 23:30 <br />
                Lo：フード22:30 / ドリンク 23:00
              </div>
              <h4 class="sub-title">
                電話番号
              </h4>
              <div>
                <a href="tel:0369088351" class="phone-number">03-6908-8351</a>
              </div>
              <v-row no-gutters>
                <v-col cols="5" sm="6">
                  <h4 class="sub-title">
                    支払い方法
                  </h4>
                  <div>
                    現金・PayPay
                  </div>
                </v-col>
                <v-col class="ml-2">
                  <h4 class="sub-title">
                    店舗SNS
                  </h4>
                  <v-btn
                    v-for="item in takadanobabaSnsItems"
                    :key="item.id"
                    :href="item.link"
                    class="mr-4"
                    :color="item.color"
                    icon
                  >
                    <v-icon size="32px">
                      {{ item.icon }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-6 location location-reverse" no-gutters>
      <v-col>
        <v-card
          class="pa-2 store-detail-wrapper vertical-text"
          elevation="0"
          color="transparent"
        >
          <div class="title-relative">
            <h3 class="sub-title title-absolute">
              東中野店
            </h3>
            <div class="location-border">
              <h4 class="sub-title">
                店舗住所
              </h4>
              <div>
                東京都中野区東中野3丁目6−18
              </div>

              <h4 class="sub-title">
                アクセス
              </h4>
              <div>
                中央総武線東中野駅から徒歩4分<br />
                <v-btn
                  href="https://g.page/yakitonteruteru2?share"
                  target="_blank"
                  rel="noopener"
                  elevation="0"
                  color="transparent"
                  class="yakiton-location-map"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                  Googleマップで開く</v-btn
                >
              </div>

              <h4 class="sub-title">
                営業時間
              </h4>
              <div>
                平日・土日：11:30 - 23:30 <br />
                Lo：フード22:30 / ドリンク 23:00
              </div>

              <h4 class="sub-title">
                電話番号
              </h4>
              <div>
                <a href="tel:0369158820" class="phone-number">03-6915-8820</a>
              </div>
              <v-row no-gutters>
                <v-col cols="5" sm="6">
                  <h4 class="sub-title">
                    支払い方法
                  </h4>
                  <div>
                    現金・PayPay
                  </div>
                </v-col>
                <v-col class="ml-2">
                  <h4 class="sub-title">
                    店舗SNS
                  </h4>
                  <v-btn
                    v-for="item in higashinakanoSnsItems"
                    :key="item.id"
                    :href="item.link"
                    class="mr-4"
                    :color="item.color"
                    icon
                  >
                    <v-icon size="32px">
                      {{ item.icon }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="store-picture">
        <v-card class="mr-2">
          <v-img
            :src="require('@/assets/YakitonTeruTeru/yakiton_higashinakano.jpg')"
            alt="やきとんてるてる東中野店舗外装写真"
            decoding="async"
            class="set-priority"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-6 location" no-gutters>
      <v-col cols="12" sm="6" md="6" lg="6" class="store-picture">
        <v-card class="mr-2">
          <v-img
            :src="require('@/assets/YakitonTeruTeru/yakiton_hibarigaoka.jpg')"
            alt="やきとんてるてるひばりヶ丘店舗外装写真"
            decoding="async"
            class="store-picture-relative"
          ></v-img>
        </v-card>
      </v-col>
      <v-col>
        <div
          class="pa-2 store-detail-wrapper vertical-text"
          elevation="0"
          color="transparent"
        >
          <div class="title-relative">
            <h3 class="sub-title title-absolute">
              ひばりヶ丘店
            </h3>
          </div>
          <div class="location-border">
            <h4 class="sub-title">
              店舗住所
            </h4>
            <div>
              東京都西東京市ひばりが丘北2丁目2−4-2-24 HiBari4224 1階
            </div>

            <h4 class="sub-title">
              アクセス
            </h4>
            <div>
              西武有楽町・池袋線/ 副都心線<br
                class="sp-br"
              />ひばりヶ丘駅から徒歩3分 <br />
              <v-btn
                href="https://goo.gl/maps/bTwdhyJ78GiQADCF7"
                target="_blank"
                rel="noopener"
                elevation="0"
                color="transparent"
                class="yakiton-location-map"
              >
                <v-icon>mdi-chevron-double-right</v-icon>
                Googleマップで開く</v-btn
              >
            </div>

            <h4 class="sub-title">
              営業時間
            </h4>
            <div>
              平日・土日：11:30 - 23:30 <br />
              Lo：フード22:30 / ドリンク 23:00
            </div>

            <h4 class="sub-title">
              電話番号
            </h4>
            <div>
              <a href="tel:0424394280" class="phone-number">042-439-4280</a>
            </div>

            <v-row no-gutters>
              <v-col cols="5" sm="6">
                <h4 class="sub-title">
                  支払い方法
                </h4>
                <div>
                  現金・PayPay
                </div>
              </v-col>
              <v-col class="ml-2">
                <h4 class="sub-title">
                  店舗SNS
                </h4>
                <v-btn
                  v-for="item in hibarigaokaSnsItems"
                  :key="item.id"
                  :href="item.link"
                  class="mr-4"
                  :color="item.color"
                  icon
                >
                  <v-icon size="32px">
                    {{ item.icon }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="mb-6 location location-reverse" no-gutters>
      <v-col>
        <v-card
            class="pa-2 store-detail-wrapper vertical-text"
            elevation="0"
            color="transparent"
        >
          <div class="title-relative">
            <h3 class="sub-title title-absolute">
              江古田店
            </h3>
            <div class="location-border">
              <h4 class="sub-title">
                店舗住所
              </h4>
              <div>
                東京都練馬区栄町4-11
              </div>

              <h4 class="sub-title">
                アクセス
              </h4>
              <div>
                西武池袋線江古田駅から徒歩1分<br />
                <v-btn
                    href="https://goo.gl/maps/KpdkH3G8h4WcEbmc6"
                    target="_blank"
                    rel="noopener"
                    elevation="0"
                    color="transparent"
                    class="yakiton-location-map"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                  Googleマップで開く</v-btn
                >
              </div>

              <h4 class="sub-title">
                営業時間
              </h4>
              <div>
                平日・土日：11:30 - 23:30 <br />
                Lo：フード22:30 / ドリンク 23:00
              </div>

              <h4 class="sub-title">
                電話番号
              </h4>
              <div>
                <a href="tel:0369158820" class="phone-number">03-6915-8820</a>
              </div>
              <v-row no-gutters>
                <v-col cols="5" sm="6">
                  <h4 class="sub-title">
                    支払い方法
                  </h4>
                  <div>
                    現金・PayPay
                  </div>
                </v-col>
                <v-col class="ml-2">
                  <h4 class="sub-title">
                    店舗SNS
                  </h4>
                  <v-btn
                      v-for="item in ekodaSnsItems"
                      :key="item.id"
                      :href="item.link"
                      class="mr-4"
                      :color="item.color"
                      icon
                  >
                    <v-icon size="32px">
                      {{ item.icon }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" class="store-picture">
        <v-card class="mr-2">
          <v-img
              :src="require('@/assets/YakitonTeruTeru/yakiton_ekoda.jpg')"
              alt="やきとんてるてる江古田店舗外装写真"
              decoding="async"
              class="set-priority"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Location",
  data: () => {
    return {
      takadanobabaSnsItems: [
        {
          id: 0,
          icon: "mdi-instagram",
          link: "https://www.instagram.com/teruteru.baba1/",
          color: "instagram"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          link: "https://www.facebook.com/teruteru.takadanobaba",
          color: "facebook"
        },
        {
          id: 2,
          icon: "mdi-twitter",
          link: "https://twitter.com/yakitonteruteru",
          color: "twitter"
        }
      ],
      higashinakanoSnsItems: [
        {
          id: 0,
          icon: "mdi-instagram",
          link: "https://www.instagram.com/east_teruteru/?hl=ja",
          color: "instagram"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          link:
            "https://www.facebook.com/%E6%B5%A6%E9%87%8E%E5%B1%8B%E3%82%84%E3%81%8D%E3%81%A8%E3%82%93%E3%81%A6%E3%82%8B%E3%81%A6%E3%82%8B%E6%9D%B1%E4%B8%AD%E9%87%8E%E5%BA%97-240305663311261/?ref=page_internal",
          color: "facebook"
        },
        {
          id: 2,
          icon: "mdi-twitter",
          link: "https://twitter.com/east_teruteru",
          color: "twitter"
        }
      ],
      hibarigaokaSnsItems: [
        {
          id: 0,
          icon: "mdi-instagram",
          link: "https://www.instagram.com/teruteruhibari/",
          color: "instagram"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          link: "https://www.facebook.com/teruteru.hibarigaoka/",
          color: "facebook"
        },
        {
          id: 2,
          icon: "mdi-twitter",
          link: "https://twitter.com/teruteru_hibari",
          color: "twitter"
        }
      ],
      ekodaSnsItems: [
        {
          id: 0,
          icon: "mdi-instagram",
          link: "https://www.instagram.com/teruteru_ekoda/",
          color: "instagram"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          link:
              "https://www.facebook.com/profile.php?id=100083202788689",
          color: "facebook"
        },
        {
          id: 2,
          icon: "mdi-twitter",
          link: "https://twitter.com/teruteru_ekoda",
          color: "twitter"
        }
      ],
    };
  }
};
</script>

<style lang="scss" scoped>
a {
  color: black !important;
}

.sp-br {
  @include display_pc {
    display: none;
  }
}

.main-title {
  font-size: 2em;
  @include display_pc {
    font-size: 3em;
  }
}

.store-picture-relative {
  position: relative;
}

.location-reverse {
  flex-direction: column-reverse;
  @include display_tablet {
    flex-direction: row;
  }
}

.sub-title {
  margin-top: 8px;
}

.phone-number {
  @include display_pc {
    pointer-events: none;
  }
}

h3 {
  color: #003740;
  padding: 0.5em;
  display: inline-block;
  line-height: 1.3;
  background: $yakiton_main_color;
  vertical-align: middle;
  border-radius: 25px 0px 0px 25px;
  @include display_smart_phone {
    border: 3px solid white;
  }
}

h3:before {
  content: "●";
  color: #fbfaf5;
  margin-right: 8px;
}

.store-detail-wrapper {
  margin: 20px auto;
}

.location-border {
  border: dashed 2px $yakiton_sub_color;
  border-radius: 0.8em;
  padding: 0.5em 0.5em 0.5em 2em;
}

.title-relative {
  position: relative;
  @include display_tablet {
    position: static;
  }
}

.title-absolute {
  position: absolute;
  top: -100px;
  z-index: 10;
  @include display_tablet {
    position: static;
  }
}

.yakiton-location-map {
  border: 1px solid black !important;
  margin-top: 8px;
}

.store-picture {
  display: flex;
  align-items: center;
  justify-content: center;
}

.set-priority {
  z-index: 1;
}
</style>
