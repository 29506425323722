<template>
  <v-footer padless id="contact-footer">
    <v-card flat tile class="text-center" width="100%">
      <CompanyInformation></CompanyInformation>
      <CopyRight></CopyRight>
    </v-card>
  </v-footer>
</template>

<script>
import CompanyInformation from "@/components/Common/CompanyInformation";
import CopyRight from "@/components/Common/CopyRight";

export default {
  name: "Footer",
  components: { CopyRight, CompanyInformation }
};
</script>

<style lang="scss" scoped>
* {
  font-family: $footer-font-family;
}
</style>
