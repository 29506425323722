<template>
  <v-container id="apply-form">
    <NavBar></NavBar>
    <Banner></Banner>
    <router-view />
    <v-divider></v-divider>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Banner from "@/components/JobDescription/Banner";
import Footer from "@/components/Common/Footer";
import NavBar from "@/components/Common/NavBar";

export default {
  name: "ApplyFormPage",
  components: {
    NavBar,
    Footer,
    Banner
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  background-color: $main-background-color;
}
</style>
