<template>
  <v-container id="philosophy">
    <div class="title-center">
      <v-card
        class="d-flex justify-center my-10 py-12 rounded-0 main-title"
        elevation="0"
        width="80%"
      >
        <h1>
          ブルイエ経営理念
        </h1>
      </v-card>
    </div>
    <v-card flat>
      <v-card-text class="philosophy-text-sp justify-center pb-0 black--text">
        人の幸せを追求し、<br />人の可能性を信じる。
      </v-card-text>
      <v-img
        :src="require('@/assets/Philosophy/uranoya.jpg')"
        alt="Brillerが掲げる経営理念"
        width="max"
        height="80%"
        class="philosophy-picture mt-12"
      >
        <v-card-text class="philosophy-text justify-end">
          <span class="philosophy-background-color"
            >人の幸せを追求し、<br />人の可能性を信じる。</span
          >
        </v-card-text>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Main"
};
</script>

<style lang="scss" scoped>
.title-center {
  display: flex;
  justify-content: center;
}

.main-title {
  font-size: 1em;
  border-bottom: solid 2px black;
  border-top: solid 2px black;
  @include display_pc {
    font-size: 1.3em;
  }
}

.philosophy-text {
  display: none;
  @include display_tablet {
    display: flex;
    font-size: 2em;
    font-weight: bold;
    line-height: 1.8em;
    margin-right: 23px;
  }
  @include display_pc {
    display: flex;
    font-size: 2.5em;
    font-weight: bold;
    line-height: 1.5em;
  }
}

.philosophy-background-color {
  display: inline-block;
  color: black;
  text-shadow: 2px 2px 10px white, -2px 2px 10px white, 2px -2px 10px white,
    -2px -2px 10px white;
}

.philosophy-text-sp {
  display: flex;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.5em;
  @include display_tablet {
    display: none;
  }
  @include display_pc {
    display: none;
  }
}
</style>
