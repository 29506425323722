<template>
  <v-container id=" kodomo-syokudo-sns">
    <v-card flat tile class="text-center" width="100%">
      <v-card-text class="pb-0">
        <ShareNetwork
          network="twitter"
          url="https://briller-jp.com/kodomo-syokudo"
          title="飲食店経営会社Briller(ブルイエ)の企業活動こども食堂をシェア！参加費無料でこども食堂定期開催中！"
          description="飲食店経営会社Briller(ブルイエ)の企業活動こども食堂をシェア！各店舗でこども食堂を参加費無料で定期開催中！"
          hashtags="こども食堂,子供,無料開催,高田馬場,東中野,ひばりヶ丘,中板橋,江古田,Briller,ブルイエ,飲食店"
          class="mr-4"
        >
          <v-btn color="twitter" class="white--text">
            {{ snsItems[0].message }}
            <v-icon class="sns-icons white--text">
              {{ snsItems[0].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
        <ShareNetwork
          network="facebook"
          url="https://briller-jp.com/kodomo-syokudo"
          title="飲食店経営会社Briller(ブルイエ)の企業活動こども食堂をシェア！こども食堂定期開催中！"
          description="飲食店経営会社Briller(ブルイエ)の企業活動こども食堂をシェア！こども食堂を参加費無料で定期開催中！"
          quote="飲食店経営会社Briller(ブルイエ)の企業活動こども食堂をシェア！参加費無料で各店舗にてこども食堂定期開催中！"
          hashtags="こども食堂"
          class="mr-4"
        >
          <v-btn color="facebook" class="white--text">
            {{ snsItems[1].message }}
            <v-icon class="sns-icons white--text">
              {{ snsItems[1].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "SnsShare",
  data: () => {
    return {
      snsItems: [
        {
          id: 0,
          icon: "mdi-twitter",
          message: "ツイート"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          message: "シェアする"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  font-family: $footer-font-family;
}

a {
  text-decoration: none;
}
</style>
