<template>
  <v-container id="copy-right">
    <v-divider></v-divider>
    <v-card-text> © {{ year }} Briller All Rights Reserved.</v-card-text>
  </v-container>
</template>

<script>
export default {
  name: "CopyRight",
  data: () => {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  font-family: $footer-font-family;
}
</style>
