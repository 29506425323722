<template>
  <v-container id="maruteru-banner">
    <v-img
      :src="require('@/assets/Maruteru/maruteru_banner.jpg')"
      class="maruteru-banner-image"
      alt="まるてる食堂の人気モツ煮込み定食の写真"
    >
      <v-row class="fill-height ma-0" align="center" justify="center">
        <div class="pt-8 pb-8 pl-12 pr-12 banner">
          <img
            :src="require('@/assets/common/logos/maruteru_logo.png')"
            class="maruteru-logo"
            alt="まるてる食堂の店舗ロゴ"
          />
        </div>
      </v-row>
    </v-img>
  </v-container>
</template>

<script>
export default {
  name: "Banner"
};
</script>

<style lang="scss" scoped>
.maruteru-banner-image {
  height: 400px;
  width: $banner-width;
  border-radius: 0 0 50% 50% / 0 0 10% 10%;
}

.logo-center {
  margin: 10px auto;
}

.maruteru-logo {
  height: 200px;
  width: 200px;
  @include display_smart_phone {
    height: 130px;
    width: 130px;
  }
}
</style>
