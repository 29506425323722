<template>
  <v-container id="main-message">
    <v-col>
      <v-card
        class="pa-2 briller-message-title"
        elevation="0"
        color="transparent"
      >
        <h1 class="main-message-title">
          食で心からの笑顔を<br class="sp-br" />一つでも多くするため <br />
          ブルイエが今、できること
        </h1>
      </v-card>
      <v-col>
        <v-card class="briller-message" elevation="0" color="transparent">
          あらゆる面での質を追求すること<br />
          私たち自身が仕事に夢や誇りを持ち、心から幸せであること。<br />
          そして、それを分かち合うよう<br />
          来店されるお客様に喜びや驚きを与え、幸せの連鎖を生む。<br />
          飲食店はそんな存在でありたいと思います。
        </v-card>
        <v-card
          class="pa-2 mr-5 representative-name"
          elevation="0"
          color="transparent"
        >
          浦野 康輝
        </v-card>
      </v-col>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "MainMessage"
};
</script>

<style lang="scss" scoped>
.briller-message-title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1em;
  @include display_smart_phone {
    font-size: 0.7em;
  }
}

.sp-br {
  @include display_pc {
    display: none;
  }
}

h1 {
  position: relative;
  line-height: 1.4;
  padding: 0.25em 1em;
  display: inline-block;
  top: 0;
}

h1:before,
h1:after {
  position: absolute;
  top: 0;
  content: "";
  width: 8px;
  height: 100%;
  display: inline-block;
}

h1:before {
  border-left: solid 1px $briller_sub_color;
  border-top: solid 1px $briller_sub_color;
  border-bottom: solid 1px $briller_sub_color;
  left: 0;
}

h1:after {
  content: "";
  border-top: solid 1px $briller_sub_color;
  border-right: solid 1px $briller_sub_color;
  border-bottom: solid 1px $briller_sub_color;
  right: 0;
}

.briller-message {
  font-size: 1em;
  letter-spacing: 0.3em;
  line-height: 1.5em;
  padding: 0;
  margin: 1.5em 0;
  @include display_pc {
    font-size: 1.2em;
    letter-spacing: 0.3em;
    line-height: 2em;
    padding: 8px;
    margin: 23px 8px auto 120px;
  }
}

.representative-name {
  font-size: 1.5em;
  text-align: right;
  margin: 25px auto;
}
</style>
