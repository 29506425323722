export default class Applicant {
  constructor(name, kanaName, phone, email, gender, memo, robotToken) {
    this.name = name;
    this.kanaName = kanaName;
    this.phone = phone;
    this.email = email;
    this.gender = gender;
    this.memo = memo;
    this.robotToken = robotToken;
  }
}
