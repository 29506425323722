<template>
  <v-container id="job-description" class="justify-center">
    <v-card>
      <v-img
        :src="require('@/assets/JobDescription/hiring_banner.jpg')"
        alt="ブルイエの求人募集用バナー"
        class="banner-image"
      >
        <div class="logo-center">
          <img
            :src="require('@/assets/briller/briller_logo.png')"
            alt="Brillerの企業ロゴ"
            height="98"
            width="300"
            class="briller-banner-logo"
          />
        </div>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Banner"
};
</script>

<style lang="scss" scoped>
.banner-image {
  height: $banner-height;
  width: $banner-width;
}

.logo-center {
  display: flex;
  justify-content: center;
  margin: 100px auto;
}

.briller-banner-logo {
  @include display_smart_phone {
    height: 80px;
    width: 240px;
  }
}
</style>
