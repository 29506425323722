<template>
  <v-container>
    <NavBar></NavBar>
    <Banner></Banner>
    <MainMessage></MainMessage>
    <Menu></Menu>
    <Carousel></Carousel>
    <Location></Location>
    <!--    <News></News>-->
    <EmployeeRecruitment></EmployeeRecruitment>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Banner from "@/components/OsakanaTeruteru/Banner";
import NavBar from "@/components/OsakanaTeruteru/NavBar";
import MainMessage from "@/components/OsakanaTeruteru/MainMessage";
import Menu from "@/components/OsakanaTeruteru/Menu";
import Footer from "@/components/OsakanaTeruteru/Footer";
import Location from "@/components/OsakanaTeruteru/Location";
import Carousel from "@/components/OsakanaTeruteru/Carousel";
// import News from "@/components/OsakanaTeruteru/News";
import EmployeeRecruitment from "@/components/OsakanaTeruteru/EmployeeRecruitment";

export default {
  name: "OsakanaTeruteru",
  components: {
    EmployeeRecruitment,
    // News,
    Carousel,
    Location,
    Footer,
    Menu,
    MainMessage,
    NavBar,
    Banner
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  background-color: $main-background-color;
}
</style>
