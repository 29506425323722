<template>
  <v-container id="job-detail">

    <v-row no-gutters>
      <v-col>
        <v-card
          class="job-detail-container"
          align="center"
          elevation="1"
          rounded="6"
        >
          <v-card-text>
            <v-row class="detail-item-wrap">

              <v-col class="details">
                アルバイト、社員募集についての詳細は求人問い合わせフォームからお問い合わせいただくか、各店舗へ直接お問い合わせください
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                    to="/apply-form"
                    color="#386AAD"
                    class="apply-page py-7 white--text"
                >求人問い合わせフォーム</v-btn
                >
              </v-col>
            </v-row>

            <v-row class="detail-item-wrap">
              <v-col cols="12" md="4" lg="4" class="detail-item"
                >各店舗情報</v-col
              >
              <v-col class="details">
                <span class="font-style">【やきとんてるてる高田馬場店】</span
                ><br />
                東京都新宿区高田馬場3-22-4 1F<br />
                各線「高田馬場駅」より徒歩7分<br />
                電話 03-6908-8351<br />
                <v-btn
                  href="https://g.page/yakitonteruteru1?share"
                  target="_blank"
                  rel="noopener"
                  elevation="0"
                  color="transparent"
                  class="location-map"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                  Googleマップで開く
                </v-btn>
                <br />
                <br />
                <span class="font-style">【やきとんてるてる東中野店】</span
                ><br />
                東京都中野区東中野3-6-18 1F<br />
                各線「東中野駅」より徒歩4分<br />
                地下鉄「落合駅」より徒歩7分<br />
                電話 03-6915-8820<br />
                <v-btn
                  href="https://g.page/yakitonteruteru2?share"
                  target="_blank"
                  rel="noopener"
                  elevation="0"
                  color="transparent"
                  class="location-map"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                  Googleマップで開く
                </v-btn>
                <br />
                <br />
                <span class="font-style">【やきとんてるてるひばりヶ丘店】</span
                ><br />
                東京都西東京市ひばりが丘北2丁目2−4-2-24<br />
                西武池袋線「ひばりヶ丘駅」より徒歩2分<br />
                電話 042-439-4280<br />
                <v-btn
                  href="https://goo.gl/maps/bTwdhyJ78GiQADCF7"
                  target="_blank"
                  rel="noopener"
                  elevation="0"
                  color="transparent"
                  class="location-map"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                  Googleマップで開く
                </v-btn>
                <br />
                <br />
                <span class="font-style">【まるてる食堂】</span><br />
                東京都練馬区栄町31-6<br />
                西武池袋線「江古田駅」より徒歩2分<br />
                電話 03-6386-2418<br />
                <v-btn
                  href="https://goo.gl/maps/CUkB4kaEnZpaoKQ57"
                  target="_blank"
                  rel="noopener"
                  elevation="0"
                  color="transparent"
                  class="location-map"
                >
                  <v-icon>mdi-chevron-double-right</v-icon>
                  Googleマップで開く
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="question-mail-row">
              <v-col>
                <div class="question-mail-info">
                  メールでのお問い合わせはこちらまで
                </div>
                <div>
                  <a href="mailto:uranoya.higashinakano@gmail.com">uranoya.higashinakano@gmail.com</a>
                </div>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "JobDetail"
};
</script>

<style lang="scss" scoped>
* {
  font-size: 1.2rem;
  color: black;
}

#job-detail {
  padding-bottom: 10%;
}

.job-detail-container {
  padding: 16px;
  margin: auto 35px;
  @include display_smart_phone {
    padding: 0;
    margin: auto 15px;
  }
}
.job-detail-title {
  position: relative;
  font-weight: bold;
  width: 50%;
  font-size: 1.5rem;
  margin: 13px auto;
  padding: 25px 8px;
  @include display_pc {
    font-size: 2rem;
    margin: 25px auto;
  }
}

.job-detail-title:before,
.job-detail-title:after {
  content: "";
  width: 20px;
  height: 30px;
  position: absolute;
  display: inline-block;
}

.job-detail-title:before {
  border-left: solid 1px $briller_sub_color;
  border-top: solid 1px $briller_sub_color;
  top: 0;
  left: 0;
}

.job-detail-title:after {
  border-right: solid 1px $briller_sub_color;
  border-bottom: solid 1px $briller_sub_color;
  bottom: 0;
  right: 0;
}

.detail-item-wrap {
  @include display_pc {
    margin: 18px auto;
    padding: 0 32px;
  }
}

.detail-item {
  text-align: left;
  font-weight: bold;
  font-size: 0.8em;
  margin-top: 18px;
  letter-spacing: 0.8em;
  line-height: 1.4em;
  @include display_pc {
    font-size: 0.9em;
  }
}

.details {
  text-align: left;
  font-size: 0.8em;
  line-height: 1.6em;
  @include display_pc {
    line-height: 1.5em;
    font-size: 1em;
  }
}

.location-map {
  border: 1px solid black !important;
  padding: 12px;
  margin-top: 8px;
}

.question-mail-row {
  margin-top: 4%;

  .question-mail-info {
    font-weight: bold;
    font-size: 1rem;
    @include display_pc {
      font-size: 1.2rem;
    }
  }
}

.apply-page {
  width: 70%;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6% auto;
  font-size: 1rem;
  @include display_pc {
    font-size: 1.7rem;
    height: 32px;
    width: 50%;
  }
}

.font-style {
  font-weight: bold;
}
</style>
