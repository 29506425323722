import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: true,
      light: {
        facebook: "#3B5998",
        twitter: "#1DA1F2",
        instagram: "#fb3958",
        brillerNavBar: "#386AAD",
        brillerSubColor: "#6B3B3C",
        yakitonMainColor: "#d4b572",
        yakitonSubColor: "#003740",
        osakanaMainColor: "#002157",
        osakanaSubColor: "#ffde00",
        maruteruMainColor: "#d4dfbb",
        maruteruSubColor: "#faf692",
        maruteruAccentColor: "#e4af9b"
      }
    }
  }
});
