<template>
  <v-container id="employee-recruitment">
    <div class="title-center">
      <v-card
        class="d-flex justify-center my-10 py-12 rounded-0 main-title"
        elevation="0"
        width="80%"
      >
        <h2>
          採用情報
        </h2>
      </v-card>
    </div>
    <v-row justify="center" align-content="center" class="ma-0">
      <v-card flat color="transparent">
        <v-card-text class="recruitment-message black--text">
          合同会社Brillerでは<br />
          一緒に働ける仲間を<br class="sp-br" />募集しています！<br />
        </v-card-text>
        <v-card-text align="center" justify="center">
          <v-btn
            to="/job-description"
            class="ma-4 white--text link-detail"
            color="brillerSubColor"
          >
            詳しくはこちら
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-row>
    <teruteru-logos></teruteru-logos>
  </v-container>
</template>

<script>
import TeruteruLogos from "@/components/Common/TeruteruLogos";

export default {
  name: "EmployeeRecruitment",
  components: { TeruteruLogos }
};
</script>

<style lang="scss" scoped>
.title-center {
  display: flex;
  justify-content: center;
}

.main-title {
  font-size: 1em;
  border-bottom: solid 2px black;
  border-top: solid 2px black;
  background-color: transparent;
  @include display_pc {
    font-size: 1.3em;
  }
}

.link-detail {
  font-size: 1.5rem;
}

.recruitment-message {
  font-size: 28px;
  line-height: 1.5em;
}

.sp-br {
  @include display_pc {
    display: none;
  }
}
</style>
