<template>
  <v-container id="maruteru-employee-recruitment">
    <v-card>
      <v-img
        :src="require('@/assets/Maruteru/maruteru_banner.jpg')"
        height="230"
        width="100%"
        alt="まるてる食堂のモツ煮込み定食の写真"
      >
        <v-row class="fill-height ma-0" align="center" justify="center">
          <div class="py-8 px-12">
            <h2
              class="font-weight-light black--text font-weight-bold main-title"
            >
              採用情報
            </h2>
            <div
              class="subtitle-1 black--text font-weight-bold text-decoration-underline"
              align="center"
            >
              RECRUITMENT
            </div>
          </div>
        </v-row>
      </v-img>
    </v-card>

    <v-row justify="center" align-content="center" class="ma-0">
      <v-card flat class="recruitment-message-wrapper" color="transparent">
        <v-card-text class="recruitment-message black--text">
          まるてる及びてるてる各店舗では<br />
          一緒に働ける仲間を<br class="sp-br" />募集しています！<br />
        </v-card-text>
        <v-card-text align="center" justify="center">
          <v-btn
            to="/job-description"
            class="ma-2 black--text link-detail"
            color="maruteruAccentColor"
          >
            詳しくはこちら
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-row>
    <teruteru-logos></teruteru-logos>
  </v-container>
</template>

<script>
import TeruteruLogos from "@/components/Common/TeruteruLogos";

export default {
  name: "EmployeeRecruitment",
  components: { TeruteruLogos }
};
</script>

<style lang="scss" scoped>
.main-title {
  font-size: 2em;
  @include display_pc {
    font-size: 3em;
  }
}

.recruitment-message-wrapper {
  margin: auto 10px;
}

.recruitment-message {
  font-size: 1.5em;
  line-height: 1.5em;
  text-align: center;
  @include display_pc {
    font-size: 1.75em;
    line-height: 1.5em;
  }
}

.sp-br {
  @include display_pc {
    display: none;
  }
}
</style>
