<template>
  <v-container id="yakiton-carousel">
    <div
        id="yakiton-carousel-slider"
        align="center"
        class="yakiton-carousel-wrapper"
    >
      <v-carousel hide-delimiter-background height="300" class="carousel_inner">
        <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
            :alt="item.alt"
            class="carousel-items"
        ></v-carousel-item>
      </v-carousel>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      items: [
        {
          src: require("@/assets/YakitonTeruTeru/karaage.jpg"),
          alt: "大人気やきとんてるてるのジューシーカリカリ唐揚げの写真"
        },
        {
          src: require("@/assets/YakitonTeruTeru/tataki.jpg"),
          alt: "やきとんてるてるの大定番タタキの写真"
        },
        {
          src: require("@/assets/YakitonTeruTeru/motsu_curry.jpg"),
          alt: "やきとん自慢のじっくり煮込んだホロホロモツカレー写真"
        },
        {
          src: require("@/assets/YakitonTeruTeru/iidako_gutsugutsu.jpg"),
          alt: "イイダコのぐつぐつ煮の写真"
        },
        {
          src: require("@/assets/YakitonTeruTeru/bagna_cauda.jpg"),
          alt: "あつあつのバーニャカウダの写真"
        },
        {
          src: require("@/assets/YakitonTeruTeru/teisyoku.jpg"),
          alt: "やきとんランチの定番定食の写真"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
#yakiton-carousel-slider {
  margin: 15px auto;
  width: 60%;
  @include display_smart_phone {
    width: 100%;
  }
}

.yakiton-carousel-wrapper {
  border-top: 5px double black;
  border-bottom: 5px double black;
  padding: 20px;
}
</style>
