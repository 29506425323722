<template>
  <v-container>
    <v-card
      flat
      tile
      class="text-center company-information-wrapper"
      width="100%"
    >
      <v-card-title class="justify-center company-info-font-style">
        会社情報
      </v-card-title>
      <v-row
        justify="center"
        v-for="information in companyInformations"
        :key="information.id"
      >
        <v-col
          cols="3"
          align="left"
          class="company-info-title display-sp company-info-font-style"
        >
          {{ information.title }}
        </v-col>
        <v-col
          cols="6"
          sm="4"
          md="3"
          lg="3"
          align="left"
          class="display-sp company-info-font-style"
        >
          {{ information.content }}
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CompanyInformation",
  data: () => {
    return {
      companyInformations: [
        {
          id: 0,
          title: "社名",
          content: "合同会社 Briller(ブルイエ)"
        },
        {
          id: 1,
          title: "所在地",
          content: "東京都新宿区高田馬場3-22-4"
        },
        {
          id: 2,
          title: "設立",
          content: "2013年(平成25年)4月19日"
        },
        {
          id: 3,
          title: "代表",
          content: "浦野 康輝"
        },
        {
          id: 4,
          title: "従業員数",
          content: "35名"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.company-information-wrapper {
  background-color: transparent !important;
  padding-bottom: 30px;
}

.company-info-font-style {
  font-family: $footer-font-family;
}

.company-info-title {
  margin-left: 65px;
  @include display_smart_phone {
    margin-left: 40px;
  }
}

.display-sp {
  font-size: 0.8em;
  @include display_pc {
    font-size: 1em;
  }
}
</style>
