<template>
  <v-footer padless id="yakiton-footer">
    <v-card flat tile class="text-center footer-wrapper" width="100%">
      <ContactButton class="override-contact-button"></ContactButton>
      <v-card-text>
        <ShareNetwork
          network="twitter"
          url="https://briller-jp.com/yakiton-teruteru"
          title="やきとん、モツ煮込みが大人気の都内大衆居酒屋やきとんてるてる！ランチや持ち帰りもご用意しております！"
          description="やきとん、モツ煮込みが大人気の大衆居酒屋やきとんてるてる！高田馬場、東中野、ひばりヶ丘と三店舗展開！"
          hashtags="やきとんてるてる,高田馬場,東中野,ひばりヶ丘,やきとん,モツ煮込み,大衆居酒屋"
          class="mr-4"
        >
          <v-btn color="twitter">
            {{ snsItems[0].message }}
            <v-icon class="sns-icons">
              {{ snsItems[0].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
        <ShareNetwork
          network="facebook"
          url="https://briller-jp.com/yakiton-teruteru"
          title="やきとんてるてる公式ページをシェア！"
          description="やきとん、モツ煮込みが大人気の大衆居酒屋やきとんてるてる！高田馬場、東中野、ひばりヶ丘と三店舗展開！"
          quote="やきとん、モツ煮込みが大人気の都内大衆居酒屋やきとんてるてる！ランチも持ち帰りもご用意しております！"
          hashtags="やきとんてるてる"
          class="mr-4"
        >
          <v-btn color="facebook">
            {{ snsItems[1].message }}
            <v-icon class="sns-icons">
              {{ snsItems[1].icon }}
            </v-icon>
          </v-btn>
        </ShareNetwork>
      </v-card-text>
      <CompanyInformation class="company-info"></CompanyInformation>
      <CopyRight></CopyRight>
    </v-card>
  </v-footer>
</template>

<script>
import CompanyInformation from "@/components/Common/CompanyInformation";
import CopyRight from "@/components/Common/CopyRight";
import ContactButton from "@/components/Common/ContactButton";

export default {
  name: "Footer",

  components: { ContactButton, CopyRight, CompanyInformation },
  data: () => {
    return {
      snsItems: [
        {
          id: 0,
          icon: "mdi-twitter",
          color: "twitter",
          message: "ツイート"
        },
        {
          id: 1,
          icon: "mdi-facebook",
          message: "シェアする"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  font-family: $footer-font-family;
  background-color: $yakiton_sub_color;
  color: white !important;
}

a {
  text-decoration: none;
}

.override-contact-button ::v-deep .contact-button {
  margin-top: 50px;
  color: white;
  border: white solid 2px !important;
}

.sns-icons {
  background-color: transparent;
}

.company-info ::v-deep .theme--light.v-sheet {
  color: white;
}
</style>
