<template>
  <v-container id="job-description">
    <NavBar></NavBar>
    <Banner></Banner>
    <Messages></Messages>
    <JobDetail></JobDetail>
    <Footer>
      <template v-slot:sns-share>
        <SnsShare></SnsShare>
      </template>
    </Footer>
  </v-container>
</template>

<script>
import Banner from "@/components/JobDescription/Banner";
import NavBar from "@/components/Common/NavBar";
import Messages from "@/components/JobDescription/Messages";
import JobDetail from "@/components/JobDescription/JobDetail";
import Footer from "@/components/Common/Footer";
import SnsShare from "@/components/JobDescription/SnsShare";

export default {
  name: "JobDescription",
  components: {
    SnsShare,
    Footer,
    JobDetail,
    Messages,
    NavBar,
    Banner
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  background-color: $main-background-color;
}
</style>
