<template>
  <v-container id="briller">
    <NavBar></NavBar>
    <Banner></Banner>
    <Carousel></Carousel>
    <MainMessage></MainMessage>
    <CorporateAcrivity></CorporateAcrivity>
    <AssociatedLocation></AssociatedLocation>
    <EmployeeRecruitment></EmployeeRecruitment>
    <Footer></Footer>
  </v-container>
</template>

<script>
import Banner from "@/components/Common/BrillerLogoBanner";
import NavBar from "@/components/Common/NavBar";
import MainMessage from "@/components/Briller/MainMessage";
import Carousel from "@/components/Briller/Carousel";
import CorporateAcrivity from "@/components/Briller/CorporateActivity";
import AssociatedLocation from "@/components/Briller/AssociatedLocation";
import EmployeeRecruitment from "@/components/Briller/EmployeeRecruitment";
import Footer from "@/components/Briller/Footer";

export default {
  name: "Briller",
  components: {
    Banner,
    NavBar,
    MainMessage,
    Carousel,
    CorporateAcrivity,
    AssociatedLocation,
    EmployeeRecruitment,
    Footer
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  background-color: $main-background-color;
}
</style>
