<template>
  <v-form id="apply-form">
    <v-row no-gutters>
      <v-col class="pa-0">
        <v-card-text flat align="center">
          <h2 class="apply-form-title">
            お問い合わせ<br class="sp-br" />フォーム
          </h2>
        </v-card-text>
      </v-col>
    </v-row>

    <div class="apply-form-wrap">
      <validation-observer ref="obs" v-slot="ObserverProps">
        <v-row class="div-wrap-username">
          <v-col class="form-item">
            <validation-provider
              rules="required|all_string"
              name="名前"
              v-slot="{ errors }"
            >
              <v-text-field
                type="text"
                id="name"
                for="name"
                :error-messages="errors"
                label="お名前（フルネーム）"
                v-model="applicant.name"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="div-wrap-email">
          <v-col class="form-item">
            <validation-provider
              rules="email"
              name="メールアドレス"
              v-slot="{ errors }"
            >
              <v-text-field
                for="email"
                type="email"
                id="email"
                :error-messages="errors"
                v-model="applicant.email"
                label="メールアドレス"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="div-wrap-memo">
          <v-col class="form-item">
            <validation-provider name="問い合わせ内容" v-slot="{ errors }">
              <v-textarea
                for="memo"
                id="memo"
                :error-messages="errors"
                v-model="applicant.memo"
                label="お問い合わせ内容"
                dense
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="div-wrap-caution">
          <v-col>
            ※営業目的の投稿はご遠慮ください
          </v-col>
        </v-row>
        <vue-recaptcha
          ref="recaptcha"
          :sitekey="$config.recaptchaSiteKey"
          @verify="onVerify"
          @expired="onExpired"
        >
          <v-btn
            @click="checkRobot()"
            :disabled="ObserverProps.invalid"
            class="submit-button white--text"
            elevation="2"
            color="brillerNavBar"
          >
            <Loading v-show="loading"></Loading>
            送信する
          </v-btn>
        </vue-recaptcha>
      </validation-observer>
    </div>
  </v-form>
</template>

<script>
import Applicant from "@/models/applicant";
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
import "@/plugins/vee-validate";
import Loading from "@/components/Common/Loading";

export default {
  name: "ApplyForm",
  data() {
    return {
      applicant: new Applicant("", "", "", "", "", "", ""),
      successful: false,
      loading: false
    };
  },
  components: {
    Loading,
    VueRecaptcha
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    handleApplyForm() {
      const data = new URLSearchParams();
      data.append("name", this.applicant.name);
      data.append("email", this.applicant.email);
      data.append("memo", this.applicant.memo);
      data.append("robotToken", this.applicant.robotToken);

      this.loading = true;
      const options = {
        method: "POST",
        url:
          "https://briller-build-run-api-njeuz4cpyq-an.a.run.app/api/customer/contact",
        data: data
      };
      axios(options)
        .then(() => {
          this.$router.push({ path: "/contact/finish-contact" });
        })
        .catch(() => {
        });
    },
    onVerify(res) {
      this.applicant.robotToken = res;
      this.$refs.recaptcha.reset();
      this.handleApplyForm();
    },
    onExpired() {
      this.applicant.robotToken = "";
      this.$refs.recaptcha.reset();
    },
    checkRobot() {
      this.$refs.recaptcha.execute();
    }
  }
};
</script>

<style lang="scss">
.apply-form-wrap {
  width: 75%;
  margin: 32px auto;
}

.apply-form-title {
  position: relative;
  font-weight: bold;
  line-height: 2.6rem;
  width: 50%;
  font-size: 1.5rem;
  margin: 13px auto;
  @include display_pc {
    font-size: 2rem;
    margin: 25px auto;
    padding: 25px 8px;
  }
}

.apply-form-title:before,
.apply-form-title:after {
  content: "";
  width: 20px;
  height: 30px;
  position: absolute;
  display: inline-block;
}

.apply-form-title:before {
  border-left: solid 1px $briller_sub_color;
  border-top: solid 1px $briller_sub_color;
  top: 0;
  left: 0;
}

.apply-form-title:after {
  border-right: solid 1px $briller_sub_color;
  border-bottom: solid 1px $briller_sub_color;
  bottom: 0;
  right: 0;
}

.form-item {
  font-size: 1.3em;
}

.gender {
  margin-right: 32px;
}

.submit-button {
  width: 50%;
  height: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8% auto;
  font-size: 1rem;
  @include display_pc {
    font-size: 1.3em;
    height: 60px;
  }
}

.sp-br {
  @include display_pc {
    display: none;
  }
}
</style>
