import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@/config/typekit.js";
import SocialSharing from "vue-social-sharing";
import config from "./config/index";

Vue.use(SocialSharing);
Vue.config.productionTip = false;
Vue.prototype.$config = config;

new Vue({
  router,
  render: h => h(App),
  vuetify
}).$mount("#app");
