import Vue from "vue";
import VueRouter from "vue-router";
import ApplyFormPage from "@/views/ApplyFormPage";
import Briller from "@/views/Briller";
import YakitonTeruTeru from "@/views/YakitonTeruTeru";
import KodomoSyokudo from "@/views/KodomoSyokudo";
import JobDescription from "@/views/JobDescription";
import Philosophy from "@/views/Philosophy";
import OsakanaTeruteru from "@/views/OsakanaTeruteru";
import ContactPage from "@/views/ContactPage";
import Contact from "@/components/Contact/ContactMain";
import FinishContact from "@/components/Contact/FinishContact";
import Maruteru from "@/views/Maruteru";
import ApplyForm from "@/components/ApplyForm/ApplyForm";
import ApplyFinishForm from "@/components/ApplyForm/ApplyFinishForm";

Vue.use(VueRouter);

const routes = [
  {
    path: "/apply-form",
    name: "ApplyForm",
    component: ApplyFormPage,
    children: [
      {
        path: "",
        component: ApplyForm,
        meta: {
          title:
            "都内に多数あるBriller（ブルイエ）経営店舗の求人応募フォームページ",
          description:
            "やきとん居酒屋、鮮魚食堂などたくさんの店舗展開をしているBriller（ブルイエ）は従業員募集中です！応募はこちらから！",
          icon: "/favicon/briller_logo_icon.ico"
        }
      },
      {
        path: "finish",
        component: ApplyFinishForm,
        meta: {
          title:
            "都内に多数あるBriller（ブルイエ）経営店舗の求人応募フォームページ",
          description:
            "やきとん居酒屋、鮮魚食堂などたくさんの店舗展開をしているBriller（ブルイエ）は従業員募集中です！応募はこちらから！",
          icon: "/favicon/briller_logo_icon.ico"
        }
      }
    ]
  },
  {
    path: "/yakiton-teruteru",
    name: "YakitonTeruTeru",
    component: YakitonTeruTeru,
    meta: {
      title: "やきとん、焼き鳥多数ご用意！都内人気居酒屋やきとんてるてる",
      description:
        "パリッと香ばしいやきとん・焼き鳥、トロッと溶けるモツ煮込みなど酒の肴に欠かせない品が勢揃い！お持ち帰りメニューやランチも大人気、大衆やきとん居酒屋やきとんてるてる！",
      icon: "/favicon/yakiton_logo.ico",
      "og:title": "都内店舗展開、人気居酒屋やきとんてるてる",
      "og:type": "website",
      "og:url":
        "https://briller-jp.com/briller",
      "og:image":
        "https://briller-jp.com/img/takadanobaba.b5f09370.jpg"
    }
  },
  {
    path: "/",
    name: "Briller",
    component: Briller,
    meta: {
      title: "都内お食事処を多数経営する会社Briller（ブルイエ）",
      description:
        "都内に複数店舗お食事処を経営する合同会社Briller（ブルイエ）！やきとん居酒屋をはじめ、北海道や豊洲市場で仕入れた新鮮な魚を味わえる食堂や本格的なエスニック料理店まで幅広く店舗展開！",
      icon: "/favicon/briller_logo_icon.ico",
      "og:title": "都内に複数店舗展開する飲食店経営会社Briller（ブルイエ）",
      "og:type": "website",
      "og:url":
        "https://briller-jp.com/briller",
      "og:image":
        "https://briller-jp.com/img/briller_logo.ca14c192.png"
    }
  },
  {
    path: "/job-description",
    name: "JobDescription",
    component: JobDescription,
    meta: {
      title: "都内に多数あるBriller（ブルイエ）経営店舗の求人募集要項",
      description:
        "やきとんてるてる、まるてる食堂では一緒に働ける仲間を募集しています！詳しい募集要項はこちら！",
      icon: "/favicon/briller_logo_icon.ico"
    }
  },
  {
    path: "/kodomo-syokudo",
    name: "KodomoSyokudo",
    component: KodomoSyokudo,
    meta: {
      title:
        "こども食堂定期開催！都内で多数のお食事処を経営する会社Briller（ブルイエ）の企業活動",
      description:
        "合同会社Briller（ブルイエ）が経営するお食事てるてる各店舗では定期的にこども食堂を開催しています！全ての子供たちに美味しいご飯と明るい未来を提供するために無料で美味しいご飯をご提供致します！",
      icon: "/favicon/briller_logo_icon.ico"
    }
  },
  {
    path: "/philosophy",
    name: "Philosophy",
    component: Philosophy,
    meta: {
      title: "合同会社Briller（ブルイエ）の経営理念",
      description:
        "都内多数店舗展開をするお食事処てるてる各店舗を経営する会社Briller（ブルイエ）の掲げる経営理念！",
      icon: "/favicon/briller_logo_icon.ico"
    }
  },
  {
    path: "/contact",
    name: "ContactPage",
    component: ContactPage,
    children: [
      {
        path: "",
        component: Contact,
        meta: {
          title: "合同会社Briller（ブルイエ）への問い合わせページ",
          description:
            "やきとんてるてる、まるてる食堂など多数店舗展開をする合同会社Briller（ブルイエ）のお問い合わせページ！お気軽にご意見・ご相談ください！",
          icon: "/favicon/briller_logo_icon.ico"
        }
      },
      {
        path: "finish-contact",
        component: FinishContact,
        meta: {
          title: "合同会社Briller（ブルイエ）への問い合わせページ",
          description:
            "やきとんてるてる、まるてる食堂など多数店舗展開をする合同会社Briller（ブルイエ）のお問い合わせページ完了ページ！お問い合わせありがとうございました！",
          icon: "/favicon/briller_logo_icon.ico"
        }
      }
    ]
  },
  {
    path: "/osakana-teruteru",
    name: "OsakanaTeruteru",
    redirect: "/",
    component: OsakanaTeruteru,
    meta: {
      title:
        "（閉店）おさかなてるてる中板橋店！豊洲市場で仕入れた新鮮なお魚を使った料理を多数ご用意！",
      description:
        "豊洲市場や北海道から直に仕入れた新鮮な魚を使ったお料理！海鮮丼やお刺身はもちろん、魚に合わせて煮付けや揚げ物、アヒージョまでご用意しています！お持ち帰りやランチメニューも大人気！",
      icon: "/favicon/osakana_logo.ico"
    }
  },
  {
    path: "/maruteru",
    name: "Maruteru",
    component: Maruteru,
    meta: {
      title:
        "まるてる食堂江古田店！日本の食文化の一つである定食と酒の肴を合わせて味わえる新しい食堂！",
      description:
        "まるてる食堂名物の煮込み定食やデカ唐揚げ定食はもちろん、居酒屋メニューも多数揃え食堂としても、気分を変え軽く一杯お楽しみ頂くことも可能な新しい食堂です！",
      icon: "/favicon/maruteru_logo.ico"
    }
  }
];

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
