import { render, staticRenderFns } from "./SnsShare.vue?vue&type=template&id=2efb15e8&scoped=true"
import script from "./SnsShare.vue?vue&type=script&lang=js"
export * from "./SnsShare.vue?vue&type=script&lang=js"
import style0 from "./SnsShare.vue?vue&type=style&index=0&id=2efb15e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2efb15e8",
  null
  
)

export default component.exports